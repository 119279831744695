import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import getUser from './Getuser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
const AnyReactComponent = ({ lat, lng, htmlContent, isSelected }) => (
    <div lat={lat} lng={lng}>
        <div style={{ position: 'relative' }}>
            <img
                src={isSelected ? 'red-marker.svg' : 'blue-marker.svg'}
                alt=""
                style={{ width: '20px', height: '20px' }}
            />
            {isSelected && (
                <div
                    style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '-50%',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        padding: '5px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                    }}
                >
                    <h6
                        className={isSelected ? 'text-danger' : 'text-primary'}
                        style={{ color: '#3050a5', fontSize: '14px', margin: 0 }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML content
                    />
                </div>
            )}
        </div>
    </div>
);

const Jobsite = () => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [markerLocation, setMarkerLocation] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state
    const [user] = useState(getUser());
    const [site_name, setJobsite] = useState('');
    const [latitude, setLat] = useState('');
    const [longitude, setLon] = useState('');
    const [diameter, setDia] = useState('');


    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editedData, setEditedData] = useState({});
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleJobsiteChange = async (e) => {
        setJobsite(e.target.value);
    };

    const handleLatChange = (e) => {
        setLat(e.target.value);
    };

    const handleLonChange = (e) => {
        setLon(e.target.value);
    };

    const handleddiameterChange = (e) => {
        setDia(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post(process.env.REACT_APP_API_URL+'/job_site', {
                site_name: site_name,
                latitude: latitude,
                longitude: longitude,
                diameter: diameter
            }, { headers });
            setJobsite('');
            setLat('');
            setLon('');
            setDia('');
            toast.success('Site Added successfully!', {
                onClose: () => window.replace('/jobsite'), // Reload the page when the toast is closed
                // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.replace('/jobsite') // Reload the page after a delay
            }, 1500);

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    };

    const handleMapClick = (event, text) => {
        const { lat, lng } = event;

        const selectedLocation = {
            lat: lat,
            lng: lng,
            text: text,
            htmlContent: `<span><p className="text-danger" style="color:#3050a5"><img src="loc.svg" alt="" /><strong>${text}</strong></p></span>`,
        };

        setSelectedLocation(selectedLocation);
    };

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);

        if (value.trim() === '') {
            setLocationSuggestions([]);
            return;
        }

        // Use Google Places Autocomplete API to get location suggestions
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: value,
                types: ['geocode', 'establishment'], // Include 'establishment' for places of interest
            },
            (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setLocationSuggestions(results);
                }
            }
        );
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();

        if (searchQuery.trim() === '') {
            return;
        }

        // Start loading
        setLoading(true);

        // Use Google Maps Geocoding API to convert search query into coordinates
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: searchQuery }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
                const location = results[0].geometry.location;
                const { lat, lng } = location;

                const selectedLocation = {
                    lat: lat(),
                    lng: lng(),
                    text: searchQuery,
                    htmlContent: `<span><p className="text-danger" style="color:#000"><img src="loc.svg" alt="" /><strong>${searchQuery}</strong></p></span>`,
                };

                setSelectedLocation(selectedLocation);
                setMarkerLocation(selectedLocation); // S
            }

            // Stop loading
            setLoading(false);
        });
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchQuery(suggestion.description);
        setLocationSuggestions([]);

        // Start loading
        setLoading(true);

        // Use Google Maps Geocoding API to convert selected suggestion into coordinates
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
                const location = results[0].geometry.location;
                const { lat, lng } = location;

                const selectedLocation = {
                    lat: lat(),
                    lng: lng(),
                    text: suggestion.description,
                    htmlContent: `<span><h6 className="text-danger" style="color:#3050a5"><img src="loc.svg" alt="" /><strong>${suggestion.description}</strong></h6></span>`,
                };

                setSelectedLocation(selectedLocation);
                setMarkerLocation(selectedLocation); // Set the marker location

            }

            // Stop loading
            setLoading(false);
        });
    };
    useEffect(() => {
        if (selectedLocation) {
            setJobsite(selectedLocation.text || '');
            setLat(selectedLocation.lat || '');
            setLon(selectedLocation.lng || '');
        }


    }, [selectedLocation]);
    const locations = [];

    const mapOptions = {
        center: selectedLocation ? selectedLocation : { lat: 24.710903, lng: 46.6691708 },
        zoom: 18,
    };






    return (

        <div className="content-wrapper bg-white">
            <section className="content mt-4">

                <div className="container-fluid">
                    {user ? (
                        <div>
                            {user.role == 'Admin' || user.role == 'SuperAdmin' ? (
                                <div>
                                    <div className="row ml-2 " style={{ marginTop: '100px', marginBottom: '250px' }}>
                                        <div className='col-md-6'>
                                            <div style={{ height: '370px', width: '100%' }}>
                                                <div className='col-md-12 text-center'>
                                                    <form onSubmit={handleSearchSubmit}>
                                                        <input
                                                            className='mt-4 mb-4 p-2 w-50'
                                                            type="text"
                                                            value={searchQuery}
                                                            onChange={handleSearchInputChange}
                                                            placeholder="Search for a location"
                                                        />
                                                        <button type="submit" className='bg-c p-2'>Search</button>
                                                    </form>
                                                </div>

                                                {loading && <p>Loading...</p>} {/* Display loading message */}
                                                {locationSuggestions.length > 0 && (
                                                    <ul style={{ textDecoration: "none", listStyle: 'none', fontFamily: '18px' }}>
                                                        {locationSuggestions.map((suggestion) => (
                                                            <li style={{ fontSize: '20px', background: '#fff', margin: '5px', padding: '5px' }} key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)}>
                                                                {suggestion.description}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: 'AIzaSyDyBKY9mLTI-MXIFF8ZXFRBWuNANd3HC7c' }}
                                                    center={selectedLocation ? { lat: selectedLocation.lat, lng: selectedLocation.lng } : mapOptions.center}
                                                    zoom={mapOptions.zoom}
                                                    onClick={(event) => handleMapClick(event, searchQuery)}
                                                >
                                                    {markerLocation && (
                                                        <AnyReactComponent
                                                            lat={markerLocation.lat}
                                                            lng={markerLocation.lng}
                                                            htmlContent={markerLocation.htmlContent}
                                                            isSelected={true}
                                                        />
                                                    )}
                                                    {locations.map((location, index) => (
                                                        <AnyReactComponent
                                                            key={index}
                                                            lat={location.lat}
                                                            lng={location.lng}
                                                            htmlContent={location.htmlContent}
                                                        />
                                                    ))}
                                                </GoogleMapReact>

                                            </div>
                                        </div>
                                        <div className='col-md-6' style={{ marginTop: '60px' }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-md-12 text-left '>
                                                        <h4 className=' mt-4 mb-2'>Create A Job Site </h4>
                                                        <small className='' style={{ marginBottom: '10px' }}><strong>Note:</strong> If Job Site Choose From Maps Section data will come automatically </small>

                                                    </div>
                                                    <div className='col-md-12 mt-4 text-left'>
                                                        <input type="text" className="form-control p-4" placeholder="Job Site Name" value={site_name} onChange={handleJobsiteChange} required />
                                                    </div>
                                                    <div className='col-md-12  mt-2 text-left'>
                                                        <input type="number" className="form-control p-4" placeholder="Area Diameter Exp: 2000,5000,100000 etc" value={diameter} onChange={handleddiameterChange} required />
                                                    </div>
                                                    <div className='col-md-12  mt-2'>
                                                        <input type="text" className="form-control p-4" placeholder="Latitude" value={latitude} onChange={handleLatChange} required />
                                                    </div>
                                                    <div className='col-md-12  mt-2'>
                                                        <input type="text" className="form-control p-4" placeholder="Longitude" value={longitude} onChange={handleLonChange} required />
                                                    </div>
                                                </div>
                                                <div className="mt-4 ">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <button type="submit" className="btn btn-block text-white p-2 mt-4 " style={{ background: '#223367' }}><i className='fa fa-plus-circle'></i> Add</button>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Link to='/jobsite' className="btn btn-block text-white p-2 mt-4 " style={{ background: '#223367' }}><i className='fa fa-arrow-right'></i> All Sites</Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>

                                        </div>
                                    </div>


                                </div>
                            ) : (
                                <div>
                                    <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
                                </div>
                            )}
                        </div>




                    ) : (
                        window.location.replace('login')
                    )
                    }
                    {/* Small boxes (Stat box) */}
                    <ToastContainer />

                </div >
            </section >
        </div >


    );
};

export default Jobsite;