import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title' style={{ fontFamily: 'Noto Kufi Arabic' }}>
              {
                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'OUR SIMPLE PROCESS' : 'عمليتنا البسيطة'
              }
            </h6>
            <h2 className='title'>

              <span style={{ fontFamily: 'Noto Kufi Arabic' }}>
                {
                  localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '   Best Step  To Get Our Service' : ' أفضل خطوة للحصول على خدمتنا'
                }

              </span>
            </h2>
          </div>
          <div className='row' style={{ fontFamily: 'Noto Kufi Arabic' }}>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/9.svg' alt='img' />
                </div>
                <div className='details' >
                  <p className='process-count'>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Step 01' : ' خطوة 01'
                    }

                  </p>
                  <h5 className='mb-3' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Register for free and enjoy a 14-day trial' :'سجل مجانا وتمتع بفترة تجريبية 14 يوما'
                    }
                    
                    </h5>

                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/10.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>
                    {
                       localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Step 02' :
                       'خطوة 02'
                    }
                    
                    </p>
                  <h5 className='mb-3' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Sign In Dashboard Using Registered Credentials' :'قم بتسجيل الدخول إلى لوحة التحكم باستخدام بيانات الاعتماد المسجلة'
                    }
                    
                    </h5>

                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/11.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>
                  {
                       localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Step 03' :
                       'خطوة 03'
                    }
                    
                    </p>
                  <h5 className='mb-3'  style={{ fontFamily: 'Noto Kufi Arabic' }}   >
                   {
                     localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Configure Dashboard of Our system' :' قم بتكوين لوحة التحكم في نظامنا   '
                   }
                    
                      </h5>

                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/12.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>
                    {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Last Step' : ' الخطوة الأخيرة'
                    }
                   
                    </p>
                  <h5 className='mb-3'  style={{ fontFamily: 'Noto Kufi Arabic' }}>
                     {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Download Our Mobile App and Sign In.  Enjoy Tracking' :
                      'قم بتنزيل تطبيقنا للجوال وسجل الدخول، استمتع بتجربة التتبع الدقيقة'
                     }
                    
                    </h5>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
