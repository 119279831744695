
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from '../Getuser';
import { useNavigate } from 'react-router';
import Http from '../../Http';
import { toast } from 'react-toastify';
import LogoLoader from '../loader';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';

const All_admin = () => {
    const [subscribers, setSubscribers] = useState([]);
    const [user] = useState(getUser());
    const [searchText, setSearchText] = useState('');
    const [editData, setEditData] = useState({});
    const [initialEditData, setInitialEditData] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [details, setDetails] = useState(null);
    const dateFormat = 'yyyy-MM-dd';
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);
    const [summaryModalOpen, setSummayModalOpen] = useState(false);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const [user_details, setUserDetails] = useState([]);
    const [status, setStatus] = useState(null);
    const [choosenPackage, setChoosenPackage] = useState('');
    const [months, setMonths] = useState('');
    const [packageUsers, setPackageusers] = useState('');
    const [detials_id, setDetails_ID] = useState('');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const handleFiltterStart = (e) => {
        setStartDate(e.target.value);

    };
    const handleFiltterEnd = (e) => {
        setEndDate(e.target.value);

    };



    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    
    const column = [
        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMM d, yyyy'),
            sortable: true
        },
        {
            name: 'Payment ID',
            selector: row => row.order_ref ? row.order_ref : '---',
            sortable: true
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row,
            sortable: true,
            cell: (row) => <a onClick={() => Activity(row)} style={{ cursor: 'pointer' }}>
                {
                    (row.status == '1' ? <span className='text-white small ' style={{ background: 'rgb(48, 80, 165)', borderRadius: '5px', padding: '2px' }}><input type='hidden' value={status} onChange={setStatus(false)} /> Active</span> : <span style={{ background: 'rgb(241, 130, 38)', borderRadius: '5px', padding: '2px' }} > <input type='hidden' value={status} onChange={setStatus(true)} />Inactive</span>)
                }
            </a>,
        },
        {
            name: 'Details',
            selector: (row) => row,
            cell: (row) => (
                <a onClick={() => showDetails(row)} className='btn-sm p-1  ' style={{ background: 'rgb(48, 80, 165)', cursor: 'pointer' }}> <i className='fa fa-eye' style={{ color: '#fff' }}></i></a>
            ),
        },

        {
            name: 'X',
            selector: (row) => row,
            cell: (row) => (
                <a className='btn-sm p-1 text-white' onClick={() => Delete_user(row)} style={{ background: 'rgb(241, 130, 38)', cursor: 'pointer' }}> <i className='fa fa-trash'></i></a>
            ),
        },
    ]

    const column2 = [
        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMM d, yyyy'),
            sortable: true
        },


        {
            name: 'Name',
            selector: row => row.name,
            sortable: true

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: (row) => (row.status == '1' ? <span className='text-white small' style={{ background: 'rgb(48, 80, 165)', borderRadius: '5px', padding: '2px' }}>Active</span> : <span style={{ background: 'rgb(241, 130, 38)', borderRadius: '5px', padding: '2px' }}>Inactive</span>),
        },
    ]
    const fetechSubscribers = async () => {



        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/all_Subscribers',
                { headers });
            if (response.status === 200) {
                setSubscribers(response.data.users);
                setEditData(response.data);
                setInitialEditData(response.data);
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechSubscribers();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = subscribers
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map((row) => ({ ...row }));
    const filteredTotalTime = filteredData.filter(({ created_at }) => {
        const dateObj = parseISO(format(created_at, dateFormat));
        return (
            (!start_date || dateObj >= parseISO(start_date)) &&
            (!end_date || dateObj <= parseISO(end_date))
        );
    });
    const openEditModal = () => {
        setIsEditModalOpen(true);
    };
    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditData(initialEditData);
    };
    const handleEditModalChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const toggleSummayModal = () => {
        setSummayModalOpen(!summaryModalOpen);
    };
    const toggleUpgradeModal = () => {
        setUpgradeModalOpen(!upgradeModalOpen);
    };

    const [main_invite,setMainInvite]= useState([]);
    const [current_payment,setCurrentPayment]= useState([]);
    
    const showDetails = (row) => {
        // const Get_payment_details = async (row) => {
        //     try {
        //         const res = await axios.post(process.env.REACT_APP_API_URL+'/payment_details', {
        //             order_ref: row.order_ref
        //         }, { headers });
        //         if (res.data.message === 'Details') {
        //             setDetails(res.data.data);
        //             setUserDetails(res.data.user_details);
        //         } else {
        //             setDetails('');
        //             setUserDetails('');

        //         }


        //     } catch (error) {
        //         toast(error);
        //     }
        // };

        //Get_payment_details(row);

        setMainInvite(row.main_invite)
        setCurrentPayment(row.main_invite.current_payment??[]);
        toggleSummayModal();

    };
    const Delete_user = async (row) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to Recover  this Data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const res = await axios.delete(process.env.REACT_APP_API_URL+ `/delete_account_admin/${row.id}`, { headers });
                if (res.data.message === 'Delete') {
                    await Swal.fire(
                        'Deleted!',
                        'Your item has been deleted.',
                        'success'
                    );
                    window.location.reload(); // Reload the page
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.data.message
                    });
                }
            }
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Error: ${error.message}`
            });
        }
    };
    const Activity = (row) => {
        const activity_change = async (row) => {
            try {
                const res = await axios.post(process.env.REACT_APP_API_URL+'/super_admin_deactive_subscriber', {
                    order_ref: row.order_ref,
                    status: status,
                }, { headers });
                if (res.data.message === 'Activity Change') {
                    toast.success('Update Successfully', {
                        onClose: () => window.location.reload() // Reload the page when the toast is closed
                        // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.reload()// Reload the page after a delay
                    }, 1500);
                } else {
                    toast.success(res.data.message, {
                        onClose: () => window.location.reload() // Reload the page when the toast is closed
                        // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.reload()// Reload the page after a delay
                    }, 1500);
                }


            } catch (error) {
                toast(error);
            }
        };

        activity_change(row);

    };
    const Edit_plan = () => {
        //setDetails_ID(id)
        //console.log('detias',id);
        toggleUpgradeModal();

    };

    const update_plan= async(e)=>{
        e.preventDefault();

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+'/update_plan', {
                invite_id: main_invite.id,
                package: choosenPackage,
                users: packageUsers,
                months: months,
            }, { headers });
            console.log(res.data.message);
            if (res.status=== 200) {
                toast.success('Plan Updated', {
                    onClose: () => window.location.reload(), // Reload the page when the toast is closed
                });
                setTimeout(() => {
                    window.location.reload(); // Reload the page after a delay
                }, 1500);
            }


        } catch (error) {
            toast(error);
        }
    }
    return (
        <div className="content-wrapper bg-white" >
            {isLoading ? <LogoLoader /> :
                <div>
                    {user && user.role == 'SuperAdmin' ? (

                        <section className="content " style={{ marginTop: '10' }}>
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-12">
                                        <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                                            <div className="col-md-3  text-uppercase pb-4 mt-2 ">

                                            </div>
                                            <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> From</span>
                                                <input type='date' className='form-control' value={start_date}
                                                    onChange={handleFiltterStart} required />
                                            </div>
                                            <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> To</span>

                                                <input type='date' className='form-control' value={end_date}
                                                    onChange={handleFiltterEnd} required />
                                            </div>
                                            {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">

                                    <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                </div> */}

                                            <Modal isOpen={summaryModalOpen} toggle={toggleSummayModal} size='lg'>
                                                <ModalHeader toggle={toggleSummayModal}>Subscribe Details  </ModalHeader>
                                                <ModalBody>
                                                    <div className='col-md-12'>
                                                        <div className='card p-1  ' style={{ background: 'rgb(34, 51, 103)' }}>
                                                            <h5 className='text-center text-white'> Summary</h5>
                                                        </div>

                                                        <div className='row text-dark'>
                                                            <div className='col-md-6'>
                                                                <h4 className='mb-4 mt-4'> Package Details:</h4>
                                                                <p><strong>Start Date :</strong> {current_payment && current_payment.created_at ? format(new Date(current_payment.created_at), 'MMMM dd, yyyy') : '---'}
                                                            </p>
                                                                <p><strong>Name :</strong>
                                                                    { main_invite.package}

                                                                </p>
                                                                <p><strong>Months :</strong> {main_invite ? (main_invite.months??0)  + ' months' : '---'}</p>
                                                                <p><strong>Users :</strong> {main_invite? main_invite.users:  '---'}</p>
                                                                <p><strong>Expire Date :</strong>

                                                                 { main_invite?.valid_until ?? '---'}</p>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <h4 className='mb-4 mt-4'> Payment Details:</h4>

                                                                <p><strong>Trasfer Ref :</strong> {current_payment.tran_ref??  '---'}</p>
                                                                <p><strong>Order Ref :</strong> {current_payment.cart_id ?? '---'}</p>
                                                                <p><strong> Transer Type :</strong> {current_payment.tran_type?? '---'}</p>
                                                                <p><strong> Amount :</strong> {current_payment && current_payment.cart_amount? current_payment?.cart_amount+' SAR' : '---'}</p>
                                                                <p><a className='btn-sm bg-c'  onClick={() => Edit_plan()}><i className='fa fa-edit'></i> Upgrade  Plan</a></p>


                                                                <Modal isOpen={upgradeModalOpen} toggle={toggleUpgradeModal} size='lg'>
                                                                    <ModalHeader toggle={toggleUpgradeModal}>Upgrade The Plan  </ModalHeader>
                                                                    <ModalBody>
                                                                        <form onSubmit={update_plan}>
                                                                            <div className='row'>

                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <select className='form-control' value={choosenPackage}
                                                                                        onChange={(e) => setChoosenPackage(e.target.value)} >
                                                                                        <option>Choose The Package</option>
                                                                                        <option value={'Started'} >
                                                                                            Started
                                                                                        </option>
                                                                                        <option value={'Professional'}>
                                                                                            Professional
                                                                                        </option>
                                                                                        <option value={'Enterprise'} >
                                                                                            Enterprise
                                                                                        </option>
                                                                                    </select>

                                                                                </div>
                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <input type="number" value={packageUsers}
                                                                                        onChange={(e) => setPackageusers(e.target.value)} className="form-control" placeholder='Add Extra Users' />
                                                                                         <input type="hidden" value={main_invite.id}
                                                                                         className="form-control" />
                                                                                </div>
                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <input type="number" value={months}
                                                                                        onChange={(e) => setMonths(e.target.value)} className="form-control" placeholder='Add Extra Months' />
                                                                                </div>
                                                                                <button type='submit' className='bg-c mt-4' > <i className='fa fa-save'></i> Save Changes </button>
                                                                            </div>

                                                                        </form>

                                                                    </ModalBody>
                                                                </Modal>
                                                            </div>
                                                            <div className='col-md-12 mt-4 mb-4'>
                                                                <p className='text-center h5'>Total Members({user_details ? user_details.length : '0'})</p>
                                                                <DataTable
                                                                    columns={column2}
                                                                    data={user_details}
                                                                    pagination
                                                                >
                                                                </DataTable>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ModalBody>
                                            </Modal>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                <h5 className="card-title text-center" style={{ fontSize: '22px' }}> Subscriber  Account Lists  ({filteredTotalTime.length})</h5>
                                            </div>
                                            {/* /.card-header */}
                                            <div className="card-body  w-100 table-responsive" >
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                                <DataTable
                                                    columns={column}
                                                    data={filteredTotalTime}
                                                    pagination
                                                >

                                                </DataTable>
                                            </div>
                                            {/* /.card-body */}
                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    ) : (
                        window.location.replace('/login')
                    )}
                </div>
            }


        </div>





    );
}

export default All_admin;