import React, { useState, useEffect, useCallback } from "react";
import getUser from "./Getuser";
import getCurrentPlan from "./GetCurrentPlanDetails";
import { Link, useNavigate } from "react-router-dom";
import Logout from "./logout";
import Login from "./login";
import Geolocation from "geolocation";
import ClockCheck from "./ClocKChecker";
import axios from "axios";
import { toast } from "react-toastify";
import { format, isDate } from "date-fns";
import LogoLoader from "./loader";

function Top() {
  const [match, targetMatch] = useState([]);
  const [targets, setTargets] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const today = format(new Date(), "MMM d");
  const today1 = format(new Date(), "d/MM/yyyy");
  const [isLoading, setIsLoading] = useState(true);
  const [currentClass, setCurrentClass] = useState("home");
  const [job_id, setJobID] = useState([]);
  const [job_id_end, setJobIDend] = useState([]);
  const [job_list, setJobList] = useState([]);
  const [last_activity, setLastActivity] = useState("Pending");
  const [today_record, setTodayRecord] = useState(null);
  const [message_count, setMessageCount] = useState(0);
  const toggleVisibility = () => {
    setCurrentClass("home");
    setIsVisible(!isVisible);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const [user] = useState(getUser());
  const [currentPlan, setCurrentPlan] = useState([]);
  const navigate = useNavigate();

  let usertext, sign;
  if (user) {
    usertext = "Sign Out";

    sign = (
      <button
        onClick={Logout}
        className="nav-link"
        style={{ background: "none", border: "none" }}
      >
        <i class="fas fa-sign-in-alt" style={{ color: "rgb(48, 80, 165)" }}></i>{" "}
        {usertext}
      </button>
    );
  } else {
    usertext = "Sign In";
    sign = (
      <Link
        to="/login"
        className="nav-link text-dark"
        style={{ background: "none", border: "none" }}
      >
        <i class="fas fa-sign-in-alt" style={{ color: "rgb(48, 80, 165)" }}></i>{" "}
        {usertext}
      </Link>
    );
  }

  const tokenCheker = async () => {
    if (user) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/token_checker",
          {
            user_id: user.id,
          }
        );
        if (response.data.message == "token found") {
          console.log(response.data.token);
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.replace("/login");
        }
        // Handle success, e.g., show a success message or redirect
      } catch (error) {
        toast(error);
        // Handle error, e.g., display an error message
      }
    }
  };

  const [isRedirectedToPlan,setRedirectedToPlan]=useState(false);
  useEffect(() => {
    const fetchPlan = async () => {
      const planData = await getCurrentPlan();

    //   if(planData && !isRedirectedToPlan && window.location.pathname !=='/current_plan'){
    //    toast.error("Your plan has expired. Redirecting to the plan page...");
    //   setTimeout(() => {
    //     window.location.href = '/current_plan'; // Adjust the URL path as necessary
    //     setRedirectedToPlan(true);
    //   }, 3000); 
    // }
     
      setCurrentPlan(planData); // Set the fetched plan data
    };

    fetchPlan();
    console.log('company current plan: ',currentPlan)
  }, [isRedirectedToPlan]);

  return (
    <div style={{ background: "#fff" }}>
      <nav className="main-header navbar navbar-expand navbar-white  ">
        {/* Left navbar links */}
        <ul className="navbar-nav navbar-white ">
          {user && user.role == "Admin" ? (
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/home" className="nav-link">
                {" "}
                <strong
                  style={{ color: "rgb(129, 123, 123)", fontSize: "19px" }}
                >
                  Team Activity
                </strong>
              </Link>
            </li>
          ) : (
            ""
          )}
          {(user && user.role == "Admin") ||
          (user && user.role == "Employee") ? (
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/timesheet" className="nav-link">
                <strong
                  style={{ color: "rgb(129, 123, 123)", fontSize: "19px" }}
                >
                  {" "}
                  TimeSheets
                </strong>{" "}
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* <li className="nav-item d-none d-sm-inline-block">
                           <a href="#" className="nav-link">  <strong style={{ color: 'rgb(129, 123, 123)',fontSize:'19px'  }}> Payroll</strong> </a>
                       </li> */}
          <div></div>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}

          <li className="nav-item mt-2 mr-4">
            <div class="dropdown">
              {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                   Dropdown button
                               </button> */}
              {user ? (
                <div>
                  <span></span>
                  <Link
                    class="text-uppercase mr-2 "
                    to="/message"
                    style={{
                      borderRadius: "50%",
                      background: "#EEEEEE",
                      padding: "15px 16px",
                      color: "rgb(34, 51, 103)",
                      fontSize: "15px",
                    }}
                  >
                    <i className="fa fa-inbox "></i>
                    <sup>{message_count && message_count.length}</sup>
                  </Link>
                  <a
                    class="text-uppercase"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      borderRadius: "50%",
                      background: "#EEEEEE",
                      padding: "15px 16px",
                      color: "rgb(34, 51, 103)",
                      fontSize: "20px",
                    }}
                  >
                    {user.name[0]}
                    {user.name[1]}
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" onClick={Logout}>
                      <i
                        class="fas fa-sign-in-alt"
                        style={{
                          color: "rgb(48, 80, 165)",
                          marginRight: "5px",
                        }}
                      ></i>
                      Sign Out{" "}
                    </a>
                    <Link class="dropdown-item" to="/profile">
                      <i
                        class="fas fa-user"
                        style={{
                          color: "rgb(48, 80, 165)",
                          marginRight: "5px",
                        }}
                      ></i>{" "}
                      Profile
                    </Link>
                  </div>
                </div>
              ) : (
                <div>{sign}</div>
              )}
            </div>
          </li>
          <li
            className="nav-item mobile-button "
            style={{
              background: "rgb(34, 51, 103)",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            <a
              className="nav-link text-white"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Top;
