import React from 'react';
import { FaCheck } from 'react-icons/fa'; // Import the check icon
import { packageFeatures, getTranslation } from './PlanProperties'; // Import existing package info and translation function

const PackageFeaturesList = ({ packageName }) => {
  // Check if the provided package exists in the packageFeatures object
  const features = packageFeatures[packageName];

  if (!features) return null; 

  // Determine language direction
  const lang = localStorage.getItem('lang');
  const listStyle = lang === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' };

  return (
    <ul style={listStyle}>
      {features.map((feature, index) => (
        <li key={index} >
          <FaCheck  />
         <span> {getTranslation(feature)}</span>
        </li>
      ))}
    </ul>
  );
};

export default PackageFeaturesList;
