import React, { useState, useEffect } from "react";
import getUser from "./Getuser";
import axios from "axios";
import LogoLoader from "./loader";
import { FaCheck } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import getCurrentPlan from "./GetCurrentPlanDetails";

const Current_plan = () => {
  const [user] = useState(getUser());
  const [currentPlan, setCurrentPlan] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };


  useEffect(() => {
    const fetchPlan = async () => {
      const planData = await getCurrentPlan();
      setCurrentPlan(planData); // Set the fetched plan data
    };

    fetchPlan();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [months, setMonths] = useState(null);
  const [users, setUsers] = useState(null);
  const [choosenPackage, setChoosenPackage] = useState(null);

  const upgradePlan = (value) => {
    setChoosenPackage(value);
    toggleModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/payments/create_invoice",
        {
          package: choosenPackage,
          months: months,
          users: users,
          invite_id: currentPlan.id,
        },
        { headers }
      );

     console.log(response.data);

      
      if (response.status === 200 ) {
        window.location.replace(response.data.redirect_url);
      }
    } catch (error) {
        console.log('create invoice response',error)

      toast(error.response.data.message);
    }
  };

  return (
    <div>
      {user && user.role == "Admin" && user.type == "Admin" ? (
        <div>
          <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>
              <span
                className="text-center"
                style={{ fontFamily: "Noto Kufi Arabic" }}
              >
                {localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "english"
                  ? "Provide Subscription Details"
                  : "عرض تفاصيل الاشتراك"}
              </span>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div
                  className="row"
                  style={
                    localStorage.getItem("lang") &&
                    localStorage.getItem("lang") === "english"
                      ? { direction: "ltr" }
                      : { direction: "rtl", fontFamily: "Noto Kufi Arabic" }
                  }
                >
                  <div className="mt-4 mb-4 text-center text-dark">
                    <h1 className="mb-2">
                      {localStorage.getItem("lang") &&
                      localStorage.getItem("lang") === "english"
                        ? "Time tracking, simplified"
                        : "تتبع الوقت، بسهولة وسلاسة"}
                    </h1>

                    <h4 className=" mt-2 mb-4">
                      {localStorage.getItem("lang") &&
                      localStorage.getItem("lang") === "english"
                        ? "Pay-as-you-grow pricing"
                        : "تسعير حسب الاستخدام"}
                    </h4>
                  </div>
                  {/* <div className='col-md-4 mt-2 mb-2'>
                          <select className='form-control' value={package1}
                            onChange={(e) => setStarted(e.target.value)} >
                            <option>Choose The Package</option>
                            <option value={'Started'} selected={choosenPackage && choosenPackage === 'Started' ? 'selected' : ''}>
                              Started
                            </option>
                            <option value={'Professional'} selected={choosenPackage && choosenPackage === 'Professional' ? 'selected' : ''}>
                              Professional
                            </option>  <option value={'Enterprise'} selected={choosenPackage && choosenPackage === 'Enterprise' ? 'selected' : ''}>
                              Enterprise
                            </option>
                          </select>
                        </div> */}
                  <div className="col-md-12 mt-2 mb-2 text-center">
                    {choosenPackage && choosenPackage ? (
                      <div>
                        <p className="h5">
                          {" "}
                          <span>
                            {localStorage.getItem("lang") &&
                            localStorage.getItem("lang") === "english"
                              ? "You Have Chosen Plan"
                              : "لقد اخترت الخطة الاحترافية"}
                          </span>{" "}
                          {choosenPackage === "Started"
                            ? localStorage.getItem("lang") &&
                              localStorage.getItem("lang") === "english"
                              ? "Started"
                              : "الخطة الأساسية"
                            : choosenPackage === "Professional"
                            ? localStorage.getItem("lang") &&
                              localStorage.getItem("lang") === "english"
                              ? "Professional"
                              : "الخطة الاحترافية"
                            : choosenPackage === "Enterprise"
                            ? localStorage.getItem("lang") &&
                              localStorage.getItem("lang") === "english"
                              ? "Enterprise"
                              : "خطة المؤسسات"
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mt-2 mb-2">
                    <select
                        value={choosenPackage}
                        onChange={(e) => setChoosenPackage(e.target.value)}
                        className="form-control"
                        required
                    >
                        <option disabled selected  value= {localStorage.getItem("lang") === "english"
                            ? "Select Plan Type"
                            : "اختر نوع الخطة"}>
                     {localStorage.getItem("lang") === "english"
                            ? "Select Plan Type"
                            : "اختر نوع الخطة"}
                        </option>
                        <option value="Started">{localStorage.getItem("lang") === "english" ? "Started" :  "الخطة الأساسية"}</option>
                        <option value="Professional">{localStorage.getItem("lang") === "english" ? "Professional" : "الخطة الأحترافية"}</option>
                        <option value="Enterprise">{localStorage.getItem("lang") === "english" ? "Enterprise" : "خطة المؤسسات"}</option>
                    </select>
                </div>
                  <div className="col-md-6 mt-2 mb-2">
                    <input
                      type="number"
                      value={users}
                      onChange={(e) => setUsers(e.target.value)}
                      className="form-control"
                      placeholder={
                        localStorage.getItem("lang") &&
                        localStorage.getItem("lang") === "english"
                          ? "Number  of User"
                          : "عدد الموظفين"
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 mt-2 mb-2">
                    <input
                      type="number"
                      value={months}
                      onChange={(e) => setMonths(e.target.value)}
                      className="form-control"
                      placeholder={
                        localStorage.getItem("lang") &&
                        localStorage.getItem("lang") === "english"
                          ? "Number of Month"
                          : "عدد الأشهر"
                      }
                      required
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <button
                      type="submit"
                      className="btn border-radius-0 w-100"
                      style={{ background: "rgb(34, 51, 103)", color: "white" }}
                    >
                      <i className="fa fa-paper-plane"></i>
                      {localStorage.getItem("lang") &&
                      localStorage.getItem("lang") === "english"
                        ? "Pay Now"
                        : " ادفع الآن"}
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
          {isLoading ? (
            <LogoLoader />
          ) : (
            <div>
              <div className="container mb-4">
                <div className="row ">
                  <div className="col-md-8 " style={{ margin: "auto" }}>
                    <div className="card text-left p-2 mt-4">
                      <div
                        className="card-header text-center "
                        style={{ background: "#223367", color: "#fff" }}
                      >
                        <h5
                          className=" text-center"
                          style={{ float: "center" }}
                        >
                          {" "}
                          Current Subscription Details{" "}
                        </h5>
                      </div>
                      <div className="row mt-4 mb-4">
                        <div className="col-md-12 text-center mb-4">
                          {currentPlan && currentPlan.day_left > 0 ? (
                            <h5>
                              {currentPlan && currentPlan.day_left} Days Left to
                              Expired Your Plan
                            </h5>
                          ) : (
                            <h5 className="text-danger">
                              Your Plan Has Been Expired
                            </h5>
                          )}
                        </div>
                        <div className="col-md-4">
                          <strong>
                            Plan Name <br />{" "}
                          </strong>{" "}
                          {currentPlan && currentPlan.package}
                        </div>
                        <div className="col-md-4">
                          <strong>Start Date</strong> <br />
                          {currentPlan?.current_payment?.created_at?.split('T')[0]?? ''}
                        </div>
                        <div className="col-md-4">
                          <strong>Expired Date</strong> <br />
                          { currentPlan?.valid_until ??  currentPlan.day_left+" left" }
                          </div>
                      </div>
                      <div className="row mt-4 mb-4">
                        <div className="col-md-4">
                          <strong>Invoice ID</strong> <br />
                          {currentPlan?.current_payment?.id} (
                          {currentPlan?.current_payment?.cart_amount} SAR)
                        </div>
                        <div className="col-md-4">
                          <strong>Users</strong> <br />
                          {currentPlan && currentPlan.users}
                        </div>
                        <div className="col-md-4">
                          <strong>Months</strong> <br />
                          {currentPlan?.current_payment?.month}
                        </div>
                      </div>
                      <div className="row mt-4 mb-4 text-center">
                        <div className="col-md-12">
                          <a
                            className=" btn btn-sm text-white"
                            onClick={() => toggleModal()}
                            style={{ background: "rgb(34, 51, 103)" }}
                          >
                            <i className="fa fa-save"></i> Upgrade Plan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
            </div>
          )}
        </div>
      ) : (
        window.location.replace("login")
      )}
    </div>
  );
};
export default Current_plan;
