
import axios from 'axios';
import React, { useState, useId } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useParams, Link } from 'react-router-dom';
import { format, parseISO, getDay } from 'date-fns';

const Hours_details = () => {
  const [user] = useState(getUser());
  const [isLoading, setIsLoading] = useState(true);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [request_user, setRequestUser] = useState(null);
  const [employeeReport,setEmployeeReport] = useState([]);
  const [reportTitle,setReportTitle]= useState('');
  const [data, setUsers] = useState([]);
  const [job_summary, setJobSummary] = useState([]);
  const dateFormat = 'yyyy-MM-dd';

  const params = useParams();
  const token = localStorage.getItem('token');

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };


  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
    setStartDate(format(sevenDaysAgo, 'yyyy-MM-dd'));
    setEndDate(format(today, 'yyyy-MM-dd'));
  }, []);

  const handleFiltterStart = (e) => {
    setStartDate(e.target.value);
  };
  const handleFiltterEnd = (e) => {

    setEndDate(e.target.value);

  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };


  const filteredData = data
    .filter((row) =>


      Object.values(row).some(
        (value) =>
          value &&
          typeof value === 'string' &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      )
    )
    .filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    })

    .map((row) => ({ ...row }));

  const filteredDatajobsummary = job_summary
    .filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    })

    .map((row) => ({ ...row }));


  useEffect(() => {
    fetechEmployeeReport(params.id,start_date,end_date);
  }, [params,start_date,end_date]);
  
  const fetechEmployeeReport = async (userId,startDate= null,endDate=null) => {

       // Fetch the data from the API
       const response = await axios.get(process.env.REACT_APP_API_URL + `/reports/${userId}/weekly_report`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: {
            from: startDate,
            to: endDate,
            only_summary: true,
        },
    });

    console.log("user report: ", response.data.data);
    if (response.status !==200) {
        throw new Error('No data available');
    }

    setEmployeeReport(response.data.data)
    setReportTitle(response.data.message)


   
  }

  


  const summayExcelReport = async (data,reportTitle, startDate = null, endDate = null) => {
    try {
      

        // Custom header column names
        const header = [
            'Date', 
            'Total Clock In', 
            'Regular Time (hr)', 
            'Break Time (hr)', 
            'Overtime (hr)', 
            'Jobs Time (hr)', 
            'Notes', 
        ];

        // Prepare the data for the excel file
        const excelData = data.map(report => {
            const combinedNotes = report.notes ? report.notes.join(' - ') : ''; // Combine notes or set empty
            return [
                format(parseISO(report.date), 'MMMM d yyyy'), // Format date
                (report.total_clock_in / 3600).toFixed(2),   // Total clock in hours
                (report.total_regular_time / 3600).toFixed(2), // Regular time in hours
                (report.total_break / 3600).toFixed(2), // Break time in hours
                (report.total_overtime / 3600).toFixed(2), // Overtime in hours
                (report.total_jobs_time / 3600).toFixed(2), // Job time in hours
                combinedNotes, // Notes
            ];
        });

        // Add the custom text row
        const titleRow = [reportTitle]; // Custom title row
        const emptyRow = []; // An empty row for separation after the title

        // Excel header style
        const headerStyle = {
            background: 'green',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px',
            border: '1px solid #ddd',
        };

        const headerWithStyle = header.map((columnName) => ({ v: columnName, s: headerStyle }));

        // Combine title, empty row, header, and data into one sheet
        const worksheet = XLSX.utils.aoa_to_sheet([
            titleRow,     // First row: Title
            emptyRow,     // Second row: Empty row
            headerWithStyle, // Third row: Header with styles
            ...excelData, // Subsequent rows: Data
        ]);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');

        // Convert workbook to a binary Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Save the file
        saveAs(excelBlob, `${reportTitle} .xlsx`);

    } catch (error) {
        console.error('Error generating the Excel file:', error);
    }
};

  return (
    <div className="content-wrapper" >
      {user ? (
        <div>
          {isLoading ? <LogoLoader /> : (
            <section className="content ">
              <div className="container-fluid">
                <div className="row " >
                  <div className="col-12">
                    <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                      <div className="col-md-3  text-uppercase pb-4 mt-2 ">

                      </div>
                      <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                        <span className='text-bold'> From</span>
                        <input type='date' className='form-control' value={start_date}
                          onChange={handleFiltterStart} required />
                      </div>
                      <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                        <span className='text-bold'> To</span>

                        <input type='date' className='form-control' value={end_date}
                          onChange={handleFiltterEnd} required />
                      </div>
                      {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">

                                                    <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                                </div> */}

                    </div>
                    <p>
                      Note: Default last 7 days on the table

                    </p>
                    <div className="card">
                      <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}>

                          {reportTitle}
                        </h5>
                      </div>
                      <div className="col-md-12 text-right" style={{ color: '#fff' }}>
                        <a onClick={() => summayExcelReport(employeeReport,reportTitle,start_date,end_date)} className='btn btn-sm mt-4  text-white' style={{ background: '#223367' }}><i className='fa fa-plus'></i> Download Report</a>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body table-responsive  " >
                        <input
                          type="text"
                          placeholder="Search..." className='mb-4'
                          value={searchText}
                          onChange={handleSearch}
                        />
                        {/* <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable> */}
                        <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                          <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                            <tr className=''>
                              <th scope="col ">Date</th>
                              <th scope="col ">CHECK IN HOURS</th>
                              <th scope="col">OVERTIME</th>

                              <th scope="col">REGULAR HOURS</th>
                              <th scope="col">BREAK TIME</th>
                              <th scope="col">JOB TIME</th>
                              <th scope="col">DETAILS</th>
                            </tr>
                          </thead>
                          <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                            {employeeReport.map((row) => {

                              return (
                                <tr key={row.user_id}  >


                                  <td>

                                    <div className='small border text-center text-white' style={{ background: 'rgb(34, 51, 103)', borderRadius: '10px' }}>
                                      <span >

                                        {row.day_name}
                                      </span> <br />
                                      <span>
                                        {format(parseISO(row.date), 'MMM d')}
                                      </span>
                                    </div>
                                  </td>
                                  <td >

                                    {

                                      (row.total_clock_in / 3600).toFixed(2) + ' hr'
                                    }
                                  </td>

                                  <td>

                                    {
                                      (row.total_overtime / 3600).toFixed(2) + ' hr'


                                    }

                                  </td>
                                  <td>
                                    {

                                      (row.total_regular_time / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>
                                  <td>
                                    {

                                      (row.total_break / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>
                                  
                                  <td>
                                    {

                                      (row.total_jobs_time / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>


                                  <td>
                                    <Link to={`/activity_details/${params.id}/${format(row.date, 'yyyy-MM-dd')}`} className=' btn-sm mt-4 ml-2 mr-2 text-white' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }}> </i>  Activity</Link>


                                  </td>
                                </tr>
                              );
                            }
                            )}


                          </tbody>
                        </table>

                      </div>
                      {/* /.card-body */}
                    </div>


                    {/* <div className="card " style={{ marginTop: '50px' }}>
                      <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}>

                          Job Sumary
                        </h5>
                      </div>
                      <div className="col-md-12 text-right" style={{ color: '#fff' }}>
                        <a onClick={() => downloadPDF2()} className='btn btn-sm mt-4  text-white' style={{ background: '#223367' }}><i className='fa fa-plus'></i> Download PDF</a>
                      </div> 
                      
                      <div className="card-body table-responsive  " >

                        <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable> 
                        <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                          <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                            <tr className=''>
                              <th scope="col ">Date</th>
                              <th scope="col ">Job Name</th>
                              <th scope="col">Duration</th>

                            </tr>
                          </thead>
                          <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                            {filteredDatajobsummary.map((row) => {

                              return (
                                <tr key={row.id}  >


                                  <td>

                                    {format(parseISO(row.created_at), 'MMMM dd, yyyy')}
                                  </td>
                                  <td >

                                    {

                                      row.job.job_name
                                    }
                                  </td>

                                  <td>
                                    ( {row.start ? format(row.start, 'h:mm a') : ''}  --
                                    {row.end ? format(row.end, 'h:mm a') : ''} )


                                  </td>



                                </tr>
                              );
                            }
                            )}


                          </tbody>
                        </table>

                      </div>
                    </div> */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          )}

        </div>

      ) : (
        window.location.replace('/login')
      )}
    </div>





  );
}

export default Hours_details;     