import { useEffect, useState } from 'react';
import axios from 'axios';
import getUser from './Getuser';
import { ToastContainer, toast } from 'react-toastify';
import LogoLoader from './loader';
import DataTable from 'react-data-table-component';
import { format, set } from 'date-fns';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Break = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [status, setStatus] = useState('');


    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);
    //modal

    const handleDelete = async () => {
        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+'/store_break_pre', {
                user_id: user.id
            }, { headers });


            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 600);
            // Remove the deleted task from the table

        } catch (error) {
            toast(error);
        }
    };
    const patchStatus = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+'/get_break_preference', { headers });
            setStatus(res.data.data.status)
            // Remove the deleted task from the table

        } catch (error) {
            toast(error);
        }
    };
    useEffect(() => {
        patchStatus();
    }, []);
    //modal
    return (

        <div className="content-wrapper bg-white" >
            {user ? (
                <div>
                    {user.role == 'Admin' || user.role == 'SuperAdmin' ? (
                        <div>
                            {isLoading ? <LogoLoader /> :
                                <div>
                                    <section className="content " style={{ paddingTop: '50px' }}>
                                        <div className="container">

                                            <div className="row"  >
                                                <div className="col-md-12 text-left " style={{ marginTop: '100px', marginBottom: '100px' }}>
                                                    <div className='card p-4'>
                                                        <h4 className='card-header bg-white pb-4' style={{ borderBottom: '1px solid #ccc', color: 'rgb(34, 51, 103)' }}>      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 28.295 32.182">
                                                                <g id="noun-break-2212157" transform="translate(-9.974 -5.5)">
                                                                    <path id="Path_64" data-name="Path 64" d="M44.358,43.185H41.633V38.536H40.517V44.3h3.841Z" transform="translate(-19.18 -20.745)" fill="#223367" />
                                                                    <path id="Path_65" data-name="Path 65" d="M33.819,39.544V38.5H29.851a7.974,7.974,0,0,0-15.909,0H9.974v3.818A11.438,11.438,0,0,0,21.4,53.744h.994a11.421,11.421,0,0,0,9.1-4.548,5,5,0,1,0,2.326-9.652ZM21.894,32.193a6.867,6.867,0,1,1-6.865,6.865A6.875,6.875,0,0,1,21.894,32.193Zm8.954,16h0a10.31,10.31,0,0,1-8.453,4.434H21.4A10.321,10.321,0,0,1,11.09,42.318v-2.7h2.842c0,.045.012.089.016.134.01.122.027.242.043.362.019.14.038.28.064.417.022.119.049.236.077.353.032.135.065.269.1.4.033.115.07.228.109.341.044.129.09.257.141.384.044.11.091.218.14.326q.084.185.176.365.081.157.168.31.1.175.209.345c.063.1.128.2.195.292s.158.217.241.322.144.183.22.272.178.2.27.3.16.169.243.25.2.183.3.272.173.153.263.225c.106.085.216.164.326.244.093.068.185.136.281.2.115.076.234.145.353.215.1.058.195.117.3.171.124.066.252.124.379.183.1.048.2.1.307.141.134.055.272.1.41.15.1.036.2.076.31.109.147.045.3.079.449.116.1.024.2.054.3.074.169.034.342.056.515.079.087.012.173.029.261.039.263.026.529.04.8.04s.536-.014.8-.04c.089-.009.174-.027.262-.039.173-.023.346-.045.515-.079.1-.02.2-.05.3-.074.15-.036.3-.071.448-.116.106-.032.207-.073.311-.109.137-.048.275-.095.409-.15.1-.043.2-.094.307-.141.128-.059.256-.117.379-.183.1-.054.2-.114.3-.172.119-.07.237-.138.352-.214.1-.064.189-.132.282-.2.11-.08.22-.158.326-.243.09-.073.177-.15.264-.226s.2-.178.3-.271.164-.166.244-.25.183-.2.27-.3.148-.18.221-.272.163-.212.24-.321.132-.194.2-.292.142-.227.209-.344c.059-.1.115-.206.169-.311s.12-.241.176-.365c.049-.108.1-.216.14-.327.051-.126.1-.254.141-.384.038-.113.076-.226.109-.341.039-.132.072-.266.1-.4.028-.117.054-.234.077-.353.026-.137.045-.277.064-.417.016-.12.032-.24.043-.362,0-.045.013-.089.016-.134H32.7v2.7A10.18,10.18,0,0,1,30.848,48.192Zm2.413.212a3.9,3.9,0,0,1-1.113-.161,11.29,11.29,0,0,0,1.671-5.926V40.666a3.889,3.889,0,0,1-.558,7.739Z" transform="translate(0 -16.061)" fill="#223367" />
                                                                    <path id="Path_66" data-name="Path 66" d="M39.059,12.927h1.116a1.15,1.15,0,0,1,.4-.87,2.232,2.232,0,0,0,0-3.212,1.136,1.136,0,0,1,0-1.739A2.242,2.242,0,0,0,41.262,5.5H40.146a1.147,1.147,0,0,1-.4.869,2.231,2.231,0,0,0,0,3.211,1.138,1.138,0,0,1,0,1.74A2.238,2.238,0,0,0,39.059,12.927Z" transform="translate(-18.264)" fill="#223367" />
                                                                    <path id="Path_67" data-name="Path 67" d="M45.85,14.81h1.116a1.15,1.15,0,0,1,.405-.871,2.232,2.232,0,0,0,0-3.211,1.136,1.136,0,0,1,0-1.739,2.242,2.242,0,0,0,.682-1.606H46.937a1.147,1.147,0,0,1-.4.869,2.231,2.231,0,0,0,0,3.211,1.137,1.137,0,0,1,0,1.739A2.242,2.242,0,0,0,45.85,14.81Z" transform="translate(-22.528 -1.182)" fill="#223367" />
                                                                    <path id="Path_68" data-name="Path 68" d="M32.269,14.81h1.116a1.15,1.15,0,0,1,.405-.871,2.232,2.232,0,0,0,0-3.211,1.136,1.136,0,0,1,0-1.739,2.242,2.242,0,0,0,.682-1.606H33.356a1.147,1.147,0,0,1-.4.869,2.231,2.231,0,0,0,0,3.211,1.137,1.137,0,0,1,0,1.739A2.242,2.242,0,0,0,32.269,14.81Z" transform="translate(-14 -1.182)" fill="#223367" />
                                                                </g>
                                                            </svg>   Break Preferences</h4>
                                                        <form>
                                                            <div className="form-check form-switch mt-4" style={{ fontSize: '26px' }}>
                                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={handleDelete} checked={status ? 'checked' : ''} />
                                                                <label className="form-check-label" style={{ color: 'rgb(34, 51, 103)' }} htmlFor="flexSwitchCheckDefault"  >
                                                                    Break Rule

                                                                </label>
                                                            </div>
                                                        </form>


                                                        <p className='mt-4 h5 text-capitalize' style={{ color: 'rgb(34, 51, 103)' }}>
                                                         



                                                               If You Enable The Setting  Team members can start and end breaks at any time while on the clock.



                                                        </p>


                                                    </div>

                                                </div>


                                                {/* /.col */}
                                            </div>
                                            {/* /.row */}
                                        </div>
                                        {/* /.container-fluid */}
                                    </section>

                                </div>


                            }

                        </div>
                    ) : (
                        <div>
                            <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
                        </div>

                    )}
                </div>


            ) : (
                window.location.replace('/login')

            )
            }

            <ToastContainer />

        </div >

    );
}
export default Break;