import React from "react";

// Helper function for translating text
const getTranslation = (text) => {
  const lang = localStorage.getItem("lang");

  const translations = {
    "Time Tracking": "تتبع الوقت",
    "Working Hours Reports": "تقارير ساعات العمل",
    // 'Email Marketing & Automation': 'تسويق البريد الإلكتروني والأتمتة للمؤسسات',
    "Jobs, Breaks & Notes": "الوظائف، الاستراحات والملاحظات",
    "Overtime": "العمل الإضافي",
    "Genrate Reports": "إنشاء التقارير",
    "Real-Time GPS Tracking": "تتبع GPS في الوقت الفعلي",
    "Smart Notification": "إشعارات ذكية",
    // 'Team Chat': 'محادثة الفريق',
    "Group Chatting": "محادثات المجموعات",
    "24/7 Technical Support": "دعم فني 24/7",
    // 'Others(API Access)': 'أخرى (الوصول إلى API)',
  };

  return lang === "english" ? text : translations[text];
};

const packageFeatures = {
  Started: [
    "Time Tracking",
    "Working Hours Reports",
    "Jobs, Breaks & Notes",
    "Overtime",
    "Genrate Reports",
  ],
  Professional: [
    "Time Tracking",
    "Working Hours Reports",
    // 'Email Marketing & Automation',
    "Jobs, Breaks & Notes",
    "Overtime",
    "Genrate Reports",
    "Real-Time GPS Tracking",
    "Smart Notification",
  ],
  Enterprise: [
    "Time Tracking",
    "Working Hours Reports",
    // 'Email Marketing & Automation',
    "Jobs, Breaks & Notes",
    "Overtime",
    "Genrate Reports",
    "Real-Time GPS Tracking",
    "Smart Notification",
    // "Team Chat",
    "Group Chatting",
    "24/7 Technical Support",
    // "Others(API Access)",
  ],
};

const allPlansContent = [
    "Time Tracking",
    "Working Hours Reports",
    // 'Email Marketing & Automation',
    "Jobs, Breaks & Notes",
    "Overtime",
    "Genrate Reports",
    "Real-Time GPS Tracking",
    "Smart Notification",
    // "Team Chat",
    "Group Chatting",
    "24/7 Technical Support",
    // "Others(API Access)",
];

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
    <g
      id="WEB-DESIGN"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
        <g id="Group-24-Copy" transform="translate(150, 1659)">
          <g id="circle_tick_black" transform="translate(373, 273)">
            <circle
              id="Oval-6"
              fill=" rgb(34, 51, 103)"
              cx={12}
              cy={12}
              r={12}
            />
            <g
              id="black_tick"
              transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)"
              fill="#FFFFFF"
            >
              <path
                d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z"
                id="Rectangle"
              />
              <path
                d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z"
                id="Rectangle"
                transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const PlanProperties = () => (
  <div className="row">
    {/* Column with Feature Names */}
    <div className="col-md-3 border">
      {allPlansContent.map((text, index) => (
        <p key={index} className="pt-2" style={{ color: "#000" }}>
          {getTranslation(text)}
        </p>
      ))}
    </div>

    {/* Columns for Each Package */}
    {Object.keys(packageFeatures).map((packageName) => (
      <div key={packageName} className="col-md-3 border text-center">
        {allPlansContent.map((feature, index) => (
          <p key={index} className="pt-2">
            {packageFeatures[packageName].includes(feature) ? <Icon /> : null}
          </p>
        ))}
      </div>
    ))}
  </div>
);

export { packageFeatures,allPlansContent, getTranslation };

export default PlanProperties;
