import { useEffect, useState } from 'react';
import axios from 'axios';
import getUser from './Getuser';
import { ToastContainer, toast } from 'react-toastify';
import LogoLoader from './loader';
import DataTable from 'react-data-table-component';
import { format, set } from 'date-fns';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Setting = () => {
 const [user] = useState(getUser());
 const token = localStorage.getItem('token');
 const [list, setList] = useState('');
 const [start_time, setStart] = useState([]);
 const [end_time, setEnd] = useState([]);
 const [list_over, setOvertime] = useState([]);
 const [edit_over, EditOver] = useState([]);

 const [work_week, setWorkWeek] = useState([]);
 const [eidtData, setEdit] = useState([]);
 const headers = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json',
 };
 //loader
 const [isLoading, setIsLoading] = useState(true);
 const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 useEffect(() => {
  // Simulating an asynchronous operation
  setTimeout(() => {
   setIsLoading(false);
  }, 500);
 }, []);
 //modal
 const validate = (work_week) => {
  let result = true;
  if (work_week === '' || work_week === null) {
   result = false
   toast('Choose  Day');
  }

  return result;

 }
 const storeWeekday = async (e) => {
  e.preventDefault();

  try {
   const res = await axios.post(process.env.REACT_APP_API_URL+'/store_week_day', {
    day_of_week: work_week,
   }, { headers });


   toast.success(res.data.message, {
    onClose: () => window.location.reload(), // Reload the page when the toast is closed
   });
   setTimeout(() => {
    window.location.reload(); // Reload the page after a delay
   }, 2000);
   // Remove the deleted task from the table

  } catch (error) {
   toast(error);
  }
 };
 //patch work day
 const patchStatus = async () => {
  try {
   const res = await axios.get(process.env.REACT_APP_API_URL+'/get_work_day', { headers });
   setList(res.data.data)
   setEdit(res.data.data);
   // Remove the deleted task from the table

  } catch (error) {
   toast(error);
  }
 };
 useEffect(() => {
  patchStatus();
 }, []);
 //update
 const UpdateWeekday = async (e) => {
  e.preventDefault();
  var ids = eidtData.id;
  try {
   const res = await axios.post(process.env.REACT_APP_API_URL +`/update_week_work/${ids}`, {
    day_of_week: eidtData.day_of_week,
   }, { headers });


   toast.success(res.data.message, {
    onClose: () => window.location.reload(), // Reload the page when the toast is closed
   });
   setTimeout(() => {
    window.location.reload(); // Reload the page after a delay
   }, 1000);
   // Remove the deleted task from the table

  } catch (error) {
   toast(error);
  }
 };
 //store overtime
 const storeOvertime = async (e) => {
  e.preventDefault();
  const today = new Date();

  // Convert start and end times to Date objects using the current date
  const startDate = new Date(`${today.getFullYear()}-${String(today.getMonth()+1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${start_time}:00`);
  const endDate = new Date(`${today.getFullYear()}-${String(today.getMonth()+1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${end_time}:00`);
  if (startDate < endDate) {
   try {
    const res = await axios.post(process.env.REACT_APP_API_URL+'/store_overtime', {
     start_time: start_time,
     end_time: end_time,
    }, { headers });


    toast.success(res.data.message, {
     onClose: () => window.location.reload(), // Reload the page when the toast is closed
    });
    setTimeout(() => {
     window.location.reload(); // Reload the page after a delay
    }, 2000);
    // Remove the deleted task from the table

   } catch (error) {
    toast(error);
   }
  } else {

   toast.warning('Start time is earlier than end time');
  }

 };

 //patch data
 const patchOverTime = async () => {
  try {
   const res = await axios.get(process.env.REACT_APP_API_URL+'/get_overtime', { headers });
   setOvertime(res.data.data)
   console.log('over time: ',res.data.data)
   EditOver(res.data.data);
   // Remove the deleted task from the table

  } catch (error) {
   toast(error);
  }
 };
 useEffect(() => {
  patchOverTime();
 }, []);
 //update overtime
 const updatetOvertime = async (e) => {
  e.preventDefault();
 var ids=edit_over.id;
 const today = new Date();

 // Convert start and end times to Date objects using the current date
//  const startDate = new Date(`${today.getFullYear()}-${String(today.getMonth()+1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${edit_over.start_time}:00`);
//  const endDate = new Date(`${today.getFullYear()}-${String(today.getMonth()+1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${edit_over.end_time}:00`);
//  if(startDate < endDate){
  try {
   const res = await axios.post(process.env.REACT_APP_API_URL+`/update_overtime/${ids}`, {
    start_time: edit_over.start_time,
    end_time: edit_over.end_time,
   }, { headers });

   toast.success(res.data.message, {
    onClose: () => window.location.reload(), // Reload the page when the toast is closed
   });
   setTimeout(() => {
    window.location.reload(); // Reload the page after a delay
   }, 2000);
   // Remove the deleted task from the table

  } catch (error) {
   toast(error);
  }
//  }else {

//   toast.warning('Start time is earlier than end time');
//  }
 

 };
 return (

  <div className="content-wrapper bg-white" >
   {user ? (
    <div>
     {user.role == 'Admin' || user.role == 'SuperAdmin' ? (
      <div>
       {isLoading ? <LogoLoader /> :
        <div>
         <section className="content " style={{ paddingTop: '50px' }}>
          <div className="container">

           <div className="row "  >
            <div className="col-md-12 text-left " style={{ marginTop: '100px', marginBottom: '100px' }}>
             <div className='card p-4'>
              <h5 className='card-header bg-white pb-2 pl-0' style={{ borderBottom: '1px solid #ccc', color: 'rgb(34, 51, 103)' }}>
               <i class="fas fa-calendar mr-1" style={{ color: 'rgb(34, 51, 103)' }}></i>

               Workweek Settings</h5>
              {list && list.length !== 0 ?

               <span>
                <form onSubmit={UpdateWeekday}>
                 <div className='row '>
                  <div className='col-md-4 mt-4'>
                   <p className='' style={{ font: '400 1rem/1.75rem Roboto,sans-serif' }}> Workweek Start Day</p>
                  </div>
                  <div className='col-md-4'>
                   <select className='form-control mt-4' value={eidtData.day_of_week}

                    onChange={(e) =>
                     setEdit((prevData) => ({
                      ...prevData,
                      day_of_week: e.target.value
                     }))
                    }
                    required>
                    <option>Choose  One</option>
                    {daysOfWeek.map((day, index) => (
                     <option key={index} value={index} selected={eidtData.day_of_week == index ? 'selected' : ''}   >
                      {day}
                     </option>
                    ))}
                   </select>
                  </div>
                  <div className='col-md-4 mt-4'>
                   <button type="submit " className='bg-c p-1'> <i className='fa fa-save'></i> Update Setting</button>

                  </div>
                 </div>
                </form>
               </span>

               :
               <span>
                <form onSubmit={storeWeekday}>
                 <div className='row '>
                  <div className='col-md-4 mt-4'>
                   <p className='' style={{ font: '400 1rem/1.75rem Roboto,sans-serif' }}> Workweek Start Day</p>
                  </div>
                  <div className='col-md-4'>
                   <select className='form-control mt-4' value={work_week}
                    onChange={(e) => setWorkWeek(e.target.value)} required>
                    <option>Choose  One</option>
                    {daysOfWeek.map((day, index) => (
                     <option key={index} value={index} >
                      {day}
                     </option>
                    ))}
                   </select>
                  </div>
                  <div className='col-md-4 mt-4'>
                   <button type="submit " className='bg-c p-1'> <i className='fa fa-save'></i> Save Setting</button>

                  </div>
                 </div>
                </form>
               </span>

              }


             </div>
             <div className='card p-4'>
              <h5 className='card-header bg-white pb-2 pl-0' style={{ borderBottom: '1px solid #ccc', color: 'rgb(34, 51, 103)', color: 'rgb(34, 51, 103)' }}>
               <i className='fa fa-clock'></i>   Overtime Settings</h5>
              {list_over ?

               <span>
                <form onSubmit={updatetOvertime}>
                 <div className='row '>
                  <div className='col-md-4 mt-4'>
                   <p className='' style={{ font: '400 1rem/1.75rem Roboto,sans-serif' }}> Work Start And End Time</p>
                  </div>
                  <div className='col-md-4 mt-4'>
                   <div className='form-group'>
                    <label>Start</label>
                    <input type='time' className='form-control' value={edit_over.start_time} 
                      onChange={(e) =>
                       EditOver((prevData) => ({
                        ...prevData,
                        start_time: e.target.value
                       }))
                      }
                    required />
                   </div>
                   <div className='form-group'>
                    <label>End</label>
                    <input type='time' className='form-control' value={edit_over.end_time} 
                         onChange={(e) =>
                          EditOver((prevData) => ({
                           ...prevData,
                           end_time: e.target.value
                          }))
                         }
                    required />
                   </div>
                   <button type="submit " className='bg-c p-1' > <i className='fa fa-save'></i> Update Time </button>

                  </div>

                 </div>
                </form>
               </span>
               : 
               <span>
               <form onSubmit={storeOvertime}>
                <div className='row '>
                 <div className='col-md-4 mt-4'>
                  <p className='' style={{ font: '400 1rem/1.75rem Roboto,sans-serif' }}> Work Start And End Time</p>
                 </div>
                 <div className='col-md-4 mt-4'>
                  <div className='form-group'>
                   <label>Start</label>
                   <input type='time' className='form-control' value={start_time} onChange={e => setStart(e.target.value)} required />
                  </div>
                  <div className='form-group'>
                   <label>End</label>
                   <input type='time' className='form-control' value={end_time} onChange={e => setEnd(e.target.value)} required />
                  </div>
                  <button type="submit " className='bg-c p-1' > <i className='fa fa-save'></i> Save Time </button>

                 </div>

                </div>
               </form>
              </span>
               
               }

             </div>
            </div>


            {/* /.col */}
           </div>
           {/* /.row */}
          </div>
          {/* /.container-fluid */}
         </section>

        </div>


       }

      </div>
     ) : (
      <div>
       <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
      </div>

     )}
    </div>


   ) : (
    window.location.replace('/login')

   )
   }

   <ToastContainer />

  </div >

 );
}
export default Setting;