
import axios from 'axios';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const AllEmployee = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [data, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [one, setOne] = useState('');
    const [dept, setDept] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [deptlist, setDepartment] = useState([]);

    const [dept_id, setDeptID] = useState('');
    const [role, setRole] = useState('');
    const [user_id, setUserID] = useState('');
    const [department_id, setDepartmentID] = useState('');
    const [job_count, setJobCount] = useState('');

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);
    //modal

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleEdit = (row) => {
        setOne(row)
        setUserID(row.id);
        toggleModal();
    };

    //loader
    const column = [

        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMMM do yyyy'),
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true
        },
        {

            name: 'Department',
            selector: row =>(row.assign_department && row.assign_department.department ? row.assign_department.department.dept_name :'--' ),
            sortable: true,
          
        },
        {
            name: 'Manage Member',
            selector: (row) => row.id,
            cell: (row) => (
                <a onClick={() => handleEdit(row)} className='btn-sm  p-1  bg-c' style={{ color: 'rgb(48, 80, 165)' }}> <i className='fa fa-edit text-white' ></i></a>
            ),
        },
        {
            name: 'X',
            selector: (row) => row.id,
            cell: (row) => (
                <a onClick={() => handleDelete(row.id)} className='btn-sm  p-1  bg-c' style={{ color: 'rgb(241, 130, 38)' }}> <i className='fa fa-trash text-white'></i></a>
            ),
        },
    ]
    const handleDelete = async (id) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL+'/member/'+id, { headers });
            // Remove the deleted task from the table
            toast.success('Member Deleted', {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    const fetechAllUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/all_employee',
                { headers });
            if (response.data.message == "All Members") {
                console.log(response.data.message);
                setUsers(response.data.data);

            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, []);
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map((row) => ({ ...row }));
    // all dept
    const fetchData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/all_departments', { headers });
            console.log(response.data.data);
            setDepartment(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    //managment member
    const processSignup = async (e) => {
        e.preventDefault();

        const mainUrl = process.env.REACT_APP_API_URL+'/update_member';
        console.log(mainUrl);
        try {
            console.log(dept_id);
            console.log(role);
            console.log(user_id);
            const response = await axios.post(mainUrl, {
                dept_id: dept_id,
                role: role,
                user_id: user_id,
            }, { headers });
            if (response.data.message == 'Member Updated') {
                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem('user', JSON.stringify(response.data.user));
                toast.success(response.data.message, {
                    onClose: () => window.location.reload(), // Reload the page when the toast is closed
                });
                setTimeout(() => {
                    window.location.reload(); // Reload the page after a delay
                }, 1500);


            } else {
                toast(response.data.message);
            }
        } catch (error) {
            toast(error.message);
        }
    };

    const handledepartment = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+'/get_department/'+department_id, { headers });
            console.log('res.data.data');
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    useEffect(() => {
        handledepartment();
    }, []);


    
    return (
        <div className="content-wrapper" >
            {user && user.role == 'SuperAdmin' || user && user.role == 'Admin' ? (
                <div>
                    {isLoading ? <LogoLoader /> : (
                        <section className="content " style={{ marginTop: '100px' }}>
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                <h3 className="card-title text-center" style={{ fontSize: '30px' }}> Member  Lists </h3>
                                            </div>
                                            {/* /.card-header */}
                                            <div className="card-body  " >
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                                <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable>

                                                <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
                                                    <ModalHeader toggle={toggleModal}>Manage  The Member </ModalHeader>
                                                    <ModalBody>
                                                        <div className='row'>
                                                            <div className='col-md-12'>

                                                                <form onSubmit={processSignup}>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-4 mt-4'>
                                                                            <h4>Select Department</h4>
                                                                            <select className='form-control' value={dept_id}
                                                                                onChange={(e) => setDeptID(e.target.value)}>
                                                                                <option>Choose Department</option>
                                                                                {deptlist.map((deptl) => (
                                                                                    <option key={deptl.value} value={deptl.id} >
                                                                                        {deptl.dept_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>

                                                                        <div className='col-md-6 mb-4 mt-4'>
                                                                            <h4>Select Role</h4>

                                                                            <select className='form-control' value={role}
                                                                                onChange={(e) => setRole(e.target.value)}>
                                                                                <option>Choose Role</option>
                                                                                <option value={'Admin'} classNamePrefix="select" >
                                                                                    Admin
                                                                                </option>
                                                                                <option value={'Employee'}>
                                                                                    Employee
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-center'>
                                                                        <button type="submit " className='bg-c p-2'>Save Changes</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                            {/* /.card-body */}
                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    )}

                </div>

            ) : (
                window.location.replace('/login')
            )}
        </div>





    );
}

export default AllEmployee;