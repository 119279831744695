
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';

const TimeSheet = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [members, setMembers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [user_id, setUserID] = useState('');
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const dateFormat = 'yyyy-MM-dd';
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleview = (row) => {
        toggleModal();
    };
    //owndata
    const [own_total, setOwnTotal] = useState(0);
    const [own_regular, setOwnReqular] = useState(0);
    const [own_over, setOwnOver] = useState(0);
    const [own_break, setOwnBreak] = useState(0);
    const [own_check_in, setOwnCheckIn] = useState(0);
    const [own_check_out, setOwnCheckOut] = useState(0);
    const [own_job_time, setOwnJobTime] = useState(0);
    //owndata
    const today = format(new Date(), 'd/MM/yyyy');

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);
    //modal


    const handleEdit = (row) => {
        setUserID(row.id);
    };




    const handleFiltterStart = (e) => {
        setStartDate(e.target.value);

    };
    const handleFiltterEnd = (e) => {
        setEndDate(e.target.value);

    };
    const fetechAllUsers = async (from =null,to=null) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/reports/company_overview_report?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`;
            const response = await axios.get(url,
                { headers });
            if (response.status ===200) {
                //console.log('overview report',response.data.data);
                setMembers(response.data.data);
            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
            fetechAllUsers(start_date,end_date);
         
    }, [start_date,end_date]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    
    const membersFilteredData = members
        .filter((row) =>

            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase()),
            )
        )
        .map((row) => ({ ...row }));



    const fetechUserOwnData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/own_total_data',
                { headers });
            console.log('today totals :',response.data.data);
            if (response.data.message == "time patch") {
                setOwnOver(response.data.data.total_overtime);
                setOwnCheckIn(response.data.data.total_clock_in);
                setOwnReqular(response.data.data.total_regular_time)
               // setOwnCheckOut(response.data.data.clock_out);
                setOwnBreak(response.data.data.total_break);
                setOwnJobTime(response.data.data.total_jobs_time);
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }

    useEffect(() => {
        if (user) {
            fetechUserOwnData();
        }
    }, []);



    const summayExcelReport = async (user, startDate = null, endDate = null) => {
        try {
            // Fetch the data from the API
            const response = await axios.get(process.env.REACT_APP_API_URL + `/reports/${user.user_id}/weekly_report`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    from: startDate,
                    to: endDate,
                    only_summary: true,
                },
            });
    
            const { data } = response.data;
            if (response.status !==200) {
                throw new Error('No data available');
            }
    
            // Custom header column names
            const header = [
                'Date', 
                'Total Clock In', 
                'Regular Time (hr)', 
                'Break Time (hr)', 
                'Overtime (hr)', 
                'Jobs Time (hr)', 
                'Notes', 
            ];
    
            // Prepare the data for the excel file
            const excelData = data.map(report => {
                const combinedNotes = report.notes ? report.notes.join(' - ') : ''; // Combine notes or set empty
                return [
                    format(parseISO(report.date), 'MMMM d yyyy'), // Format date
                    (report.total_clock_in / 3600).toFixed(2),   // Total clock in hours
                    (report.total_regular_time / 3600).toFixed(2), // Regular time in hours
                    (report.total_break / 3600).toFixed(2), // Break time in hours
                    (report.total_overtime / 3600).toFixed(2), // Overtime in hours
                    (report.total_jobs_time / 3600).toFixed(2), // Job time in hours
                    combinedNotes, // Notes
                ];
            });
    
            // Add the custom text row
            const titleRow = [`Hours Sheet for ${user.user_name}`]; // Custom title row
            const emptyRow = []; // An empty row for separation after the title
    
            // Excel header style
            const headerStyle = {
                background: 'green',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '8px',
                border: '1px solid #ddd',
            };
    
            const headerWithStyle = header.map((columnName) => ({ v: columnName, s: headerStyle }));
    
            // Combine title, empty row, header, and data into one sheet
            const worksheet = XLSX.utils.aoa_to_sheet([
                titleRow,     // First row: Title
                emptyRow,     // Second row: Empty row
                headerWithStyle, // Third row: Header with styles
                ...excelData, // Subsequent rows: Data
            ]);
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');
    
            // Convert workbook to a binary Excel file
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
            // Save the file
            saveAs(excelBlob, `${user.user_name} hoursheet.xlsx`);
    
        } catch (error) {
            console.error('Error generating the Excel file:', error);
        }
    };
    

    const detailedExcelReport = async (user, startDate = null, endDate = null) => {
        try {
            // Fetch the data from the API
            const response = await axios.get(process.env.REACT_APP_API_URL + `/reports/${user.user_id}/weekly_report`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    from: startDate,
                    to: endDate,
                },
            });
    
            const { data } = response.data;
            console.log('detailed report: ',data)
            if (response.status !== 200) {
                throw new Error('No data available');
            }
    
            // Custom header column names for detailed report
            const header = [
                'Date', 
                'Clock In', 
                'In Location', 
                'Clock Out', 
                'Out Location', 
                'Entry Total', 
                'Breaks', 
                'Jobs',
                'Job In Location',
                'Notes'
            ];
    
            // Prepare the data for the excel file
            const excelData = [];
    
             // Determine the min and max dates if startDate or endDate is null
            if (!startDate || !endDate) {
                const dates = data.map(report => new Date(report.date));
                const minDate = new Date(Math.min(...dates)).toISOString().split('T')[0];
                const maxDate = new Date(Math.max(...dates)).toISOString().split('T')[0];

                startDate = startDate || minDate;
                endDate = endDate || maxDate;
            }

            // Create a date range array
            const start = new Date(startDate);
            const end = new Date(endDate);
            const dateRange = [];
            for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
                dateRange.push(new Date(d).toISOString().split('T')[0]); // Store date in YYYY-MM-DD format
            }
    
            // Create a map of reports by date for easy access
            const reportMap = data.reduce((map, report) => {
                const date = format(parseISO(report.date), 'yyyy-MM-dd');
                map[date] = report;
                return map;
            }, {});
    
            // Iterate through the date range and populate excelData
            dateRange.forEach(date => {
                const report = reportMap[date] || {};
                const dateFormatted = format(parseISO(date), 'MMMM d yyyy');
    
                // Prepare breaks, jobs, and notes for each date
                const breaksList = report.breaks || [];
                const jobsList = report.jobs || [];
                const notesList = report.notes || [];
                const clocksList = report.clocks || [];
    
                // Determine the maximum length of the entries
                const maxEntries = Math.max(clocksList.length, breaksList.length, jobsList.length, notesList.length);
    
                // Iterate through each index up to the maximum length
                for (let i = 0; i < maxEntries; i++) {
                    const clock = clocksList[i] || {};

                    const breakInfo = breaksList[i] ? `${breaksList[i].break_start} - ${breaksList[i].break_end??'Pending'} · ${formatDuration(breaksList[i].duration)}` : '';
                    const jobInfo = jobsList[i] ? `${jobsList[i].job_name} · ${formatDuration(jobsList[i].duration )}` : '';
                    const noteInfo = notesList[i] || '';
    
                    // Create the row
                    const row = [
                        i === 0 ? dateFormatted : '', // Show the date only for the first entry
                        clock.clock_in || '', // Clock In
                        clock.clock_in_location || '', // In Location
                        clock.clock_out || '', // Clock Out
                        clock.clock_out_location || '', // Out Location
                        formatDuration(clock.duration??0) || '', // Entry Total
                        breakInfo, // Break
                        jobInfo, // Job
                        jobsList[i]? jobsList[i].job_start_location : '',
                        noteInfo // Note
                    ];
    
                    // Push the row to excelData
                    excelData.push(row);
                }
    
                // Add a blank row after each date for separation
               // excelData.push(['', '', '', '', '', '', '', '', '']);
            });
    
            // Excel header style
            const headerStyle = {
                background: 'blue',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '8px',
                border: '1px solid #ddd',
            };
    
            const headerWithStyle = header.map((columnName) => ({ v: columnName, s: headerStyle }));
    
            // Combine title, empty row, header, and data into one sheet
            const titleRow = [`Report for ${user.user_name}`];
            const emptyRow = [];
            const worksheet = XLSX.utils.aoa_to_sheet([
                titleRow,
                emptyRow,
                headerWithStyle,
                ...excelData,
            ]);
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Detailed Report');
    
            // Convert workbook to a binary Excel file
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
            // Save the file
            saveAs(excelBlob, `${user.user_name} detailed_report.xlsx`);
    
        } catch (error) {
            console.error('Error generating the Excel file:', error);
        }
    };
    
    // Helper function to format duration
    const formatDuration = (durationInSeconds) => {
        if(!durationInSeconds)
            return'';
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
    };
    
    
    
    
    
    const [job_code_id, setJobID] = useState([]);
    //get jobsumarry
    const fetecjobCode = async () => {


        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/jobcodes/'+job_code_id, {
            },
                { headers });
            if (response.status == 200) {
                //console.log(response.data.data);

            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        if (job_code_id !== null) {
            fetecjobCode();
        }
    }, [job_code_id]);


    
    return (
        <div className="content-wrapper" >
            {user ? (
                <div>
                    {isLoading ? <LogoLoader /> : (
                        <section className="content ">
                            <div className="container-fluid">
                                <div className='row mt-2'>

                                    {
                                       user && user.role === 'SuperAdmin'  ? '' :

                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}> Overview ({today}) </h5>
                                                    </div>
                                                    <div className='row p-4' style={{ color: 'rgb(34, 51, 103)' }}>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>   CHECK IN HOURS</p>
                                                            <p className='mt-4 text-bold h4'> {(own_check_in / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>   REGULAR HOURS</p>

                                                            <p className='mt-4 text-bold h4'>  {(own_regular / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>
                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      OVERTIME</p>
                                                            <p className='mt-4 text-bold h4'>  {(own_over / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      Break Time</p>

                                                            <p className='mt-4 text-bold h4'>  {(own_break / 3600).toFixed(2)}  <span className='text-lowercase'>hr</span></p>
                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      Check Out Time</p>
                                                            <p className='mt-4 text-bold h4'>  {(own_check_out / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>     Job Time</p>
                                                            <p className='mt-4 text-bold h4'> {(own_job_time / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </div>

                                    { user && user.role==="Admin"?
                                      <div className="row " >
                                      <div className="col-12">
                                          <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                                              <div className="col-md-3  text-uppercase pb-4 mt-2 ">
  
                                              </div>
                                              <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                                                  <span className='text-bold'> From</span>
                                                  <input type='date' className='form-control' value={start_date}
                                                      onChange={handleFiltterStart} required />
                                              </div>
                                              <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                                                  <span className='text-bold'> To</span>
  
                                                  <input type='date' className='form-control' value={end_date}
                                                      onChange={handleFiltterEnd} required />
                                              </div>
                                              {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">
  
                                                      <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                                  </div> */}
                                          </div>
                                          <div className="card">
                                              <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                  <h5 className="card-title text-center" style={{ fontSize: '20px' }}> {user && user.role == 'SuperAdmin' || user.role == 'Admin' ?
  
                                                      <span>
                                                          Memebers Reports   ({members.length})
                                                      </span>
                                                      :
                                                      <span>
                                                          Reports
                                                      </span>
                                                  }    </h5>
                                              </div>
                                              {/* /.card-header */}
                                              <div className="card-body table-responsive  " >
                                                  <input
                                                      type="text"
                                                      placeholder="Search..." className='mb-4'
                                                      value={searchText}
                                                      onChange={handleSearch}
                                                  />
                                                  {/* <DataTable
                                                      columns={column}
                                                      data={filteredData}
                                                      pagination
                                                  >
  
                                                  </DataTable> */}
                                                  <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                                                      <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                                                          <tr className=''>
                                                              <th scope="col ">NAME</th>
                                                              <th scope="col ">CHECK IN HOURS</th>
                                                              <th scope="col">OVERTIME</th>
  
                                                              <th scope="col">REGULAR HOURS</th>
                                                              <th scope="col">BREAK TIME</th>
                                                              <th scope="col">JOB TIME</th>
                                                              {/* <th scope="col">DETAILS</th> */}
                                                              <th scope="col">HOURS SHEET</th>
                                                              <th scope="col">TIMES SHEET</th>
                                                              <th scope="col">DETAILS</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody style={{ color: 'rgb(34, 51, 103)' }}>
  
                                                          {membersFilteredData.map((row) => {
                                                              return (
                                                                  <tr key={row.user_id}  >
  
                                                                      <td> {row.user_name}</td>
                                                                      <td >
                                                                          {
                                                                              (row.total_clock_in / 3600).toFixed(2) + ' hr'
                                                                          }
                                                                      </td>
  
                                                                      <td>
                                                                          {
                                                                              (row.total_overtime / 3600).toFixed(2) + ' hr'
                                                                          }
  
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              (row.total_regular_time / 3600).toFixed(2) + ' hr'
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              (row.total_break / 3600).toFixed(2) + ' hr'
                                                                          }
                                                                      </td>
                                                                      {/* <td>
                                                                          {
                                                                              (click_out / 3600).toFixed(2) + ' hr'
  
  
                                                                          }
  
                                                                      </td> */}
                                                                      <td>
                                                                          {
                                                                              (row.total_jobs_time / 3600).toFixed(2) + ' hr'
                                                                          }
                                                                      </td>
                                                                      {/* <td>
  
                                                                          <a className=' btn-sm mt-4 ml-2 mr-2 ' style={{ background: 'rgb(34, 51, 103)' }} onClick={() => handleview(row)}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }} ></i></a>
  
                                                                          <Modal isOpen={modalOpen} toggle={toggleModal} size='xl'>
                                                                              <ModalHeader toggle={toggleModal}>
                                                                                  <h5 style={{color:'rgb(34, 51, 103)',textTransform:'uppercase'}}>
                                                                                      <i className='fa fa-file'></i> Report For {row.name}
                                                                                  </h5>
                                                                              </ModalHeader>
                                                                              <ModalBody>
                                                                                  {
                                                                                      row.time_saver.map((t_time)=>
                                                                                      <p>
                                                                                          {  t_time.created_at}
                                                                                      </p>
                                                                                      )
                                                                                  }
  
                                                                              </ModalBody>
                                                                          </Modal>
  
                                                                      </td> */}
                                                                      <td>
                                                                          <a onClick={() => summayExcelReport(row,start_date,end_date)} className=' btn-sm mt-4 ml-2 mr-2' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-download ' style={{ color: '#fff', cursor: 'pointer' }}></i></a>
  
                                                                      </td>
                                                                      <td>
                                                                          <a onClick={() => detailedExcelReport(row,start_date,end_date)} className=' btn-sm mt-4 ml-2 mr-2' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-clock ' style={{ color: '#fff', cursor: 'pointer' }}></i></a>
                                                                      </td>
                                                                      <td>
                                                                          <Link to={`/Hours_details/${row.user_id}`} className=' btn-sm mt-4 ml-2 mr-2 text-white' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }}> </i>  Details</Link>
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                          )}
  
                                                      </tbody>
                                                  </table>
  
                                              </div>
                                              {/* /.card-body */}
                                          </div>
  
                                      </div>
                                      {/* /.col */}
                                  </div>
                                :''}
                              
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    )}

                </div>

            ) : (
                window.location.replace('/login')
            )}
        </div>





    );
}

export default TimeSheet;     