import { useEffect, useState } from 'react';
import axios from 'axios';
import getUser from './Getuser';
import { ToastContainer, toast } from 'react-toastify';
import LogoLoader from './loader';
import DataTable from 'react-data-table-component';
import { format, set } from 'date-fns';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Entry = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [status, setStatus] = useState('');


    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);
    //modal

    const handleDelete = async () => {
        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+'/store_manual_entries', {
                user_id: user.id
            }, { headers });

            
            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 600);
            // Remove the deleted task from the table

        } catch (error) {
            toast(error);
        }
    };
    const patchStatus = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+'/get_manual_entry_data', { headers });
               setStatus(res.data.data.status)
            // Remove the deleted task from the table

        } catch (error) {
            toast(error);
        }
    };
    useEffect(() => {
        patchStatus();
    }, []);
    //modal
    return (

        <div className="content-wrapper bg-white" >
            {user ? (
                <div>
                    {user.role == 'Admin' || user.role == 'SuperAdmin' ? (
                        <div>
                            {isLoading ? <LogoLoader /> :
                                <div>
                                    <section className="content " style={{ paddingTop: '50px' }}>
                                        <div className="container">

                                            <div className="row"  >
                                                <div className="col-md-12 text-left " style={{ marginTop: '100px', marginBottom: '100px' }}>
                                                    <div className='card p-4'>
                                                        <h4 className='card-header bg-white pb-4' style={{ borderBottom: '1px solid #ccc', color: 'rgb(34, 51, 103)' }}>Preference</h4>
                                                        <form>
                                                            <div className="form-check form-switch mt-4" style={{ fontSize: '26px' }}>
                                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={handleDelete} checked={status ? 'checked' : ''} />
                                                                <label className="form-check-label" style={{ color: 'rgb(34, 51, 103)' }} htmlFor="flexSwitchCheckDefault"  >
                                                                    Manual Entries
                                                                </label>
                                                            </div>
                                                        </form>

                                                        <p className='mt-4 h5 text-capitalize' style={{ color: 'rgb(34, 51, 103)' }}>
                                                            <i className='fa fa-users'></i>   Members  allowed to <strong> Manage </strong> time entries.

                                                        </p>


                                                    </div>

                                                </div>


                                                {/* /.col */}
                                            </div>
                                            {/* /.row */}
                                        </div>
                                        {/* /.container-fluid */}
                                    </section>

                                </div>


                            }

                        </div>
                    ) : (
                        <div>
                            <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
                        </div>

                    )}
                </div>


            ) : (
                window.location.replace('/login')

            )
            }

            <ToastContainer />

        </div >

    );
}
export default Entry;