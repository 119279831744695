import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import getUser from './Getuser';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import copy from 'clipboard-copy';
import { isEmpty } from 'lodash';
import LogoLoader from './loader';

const Invite = () => {
    const [user] = useState(getUser);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state
    const [email, userEmail] = useState('');


    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const handleCopyLink = (row) => {
        copy(window.location.origin + '/join?' + row.link);
        alert('Link copied to clipboard!');
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);
    //loader
    const fetechAllUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/link_invites',
                { headers });
            setLinks([response.data.data]); // as array required for DataTable template
            console.log('link: ',response.data.data);
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, []);
    const column = [

        {
            name: 'Invite link',
            selector: row => row.id,
            cell: (row) => (
                row.status == "0" ?
                    <a  className='text-danger' href={window.location.origin + '/join?' + row.link} style={{ color: '#223367' }}>{window.location.origin + '/join?' + row.link} <br/><small className='mt-2'>Link Currently Deactivited</small></a>
                    :
                    <a href={window.location.origin + '/join?' + row.link} style={{ color: '#223367' }}>{window.location.origin + '/join?' + row.link}</a>

            ),
            sortable: true
        },
        {
            name: '',
            selector: (row) => row.id,
            cell: (row) => (
                <button onClick={() => handleCopyLink(row)} className='btn-sm    bg-c text-white p-2' style={{ color: 'rgb(241, 130, 38)' }}> <i className='fa fa-link'></i> Copy Link</button>


            ),
        },
        {
            name: '',
            selector: (row) => row.id,
            cell: (row) => (
                <button onClick={() => handleDelete(row.id)} className='btn-sm  p-2 bg-c text-white' style={{ color: 'rgb(241, 130, 38)' }}> {row.status == "0" ? 'Active link' : 'Deactivate  link'} </button>


            ),
        },
    ]

    //delete
    const handleDelete = async (id) => {
        try {
            const res = await axios.put(process.env.REACT_APP_API_URL+'/link_invites/'+id,{
            }, { headers });
            // Remove the deleted task from the table
            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    //generate a link
    const handleLink = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+'/link_invites', { headers });
            // Remove the deleted task from the table
            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    //generate a link
    const sentEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+'/sent_invitation', {
                email: email
            }, { headers });
            // Remove the deleted task from the table
            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
        finally {
            setLoading(false);
          }
    };
    return (

        <div className="content-wrapper bg-white">
            <section className="content mt-4">

                <div className="container">
                    {user ? (
                        <div>
                            {user.role == 'Admin' || user.role == 'SuperAdmin' ? (

                                <div>
                                    {isLoading ? <LogoLoader /> : (
                                        <div className="card-body  " style={{ marginTop: '150px', marginBottom: '300px' }} >
                                            {isEmpty(links) ?
                                                (
                                                    <div className='' >
                                                        <h4 className='mb-4' style={{ color: 'rgb(34, 51, 103)' }}>Generate A Link for Add Members <i className='fa fa-users'></i></h4>
                                                        <button onClick={() => handleLink()} className='btn-sm  p-1  bg-c text-white p-2 h4' style={{ color: 'rgb(241, 130, 38)' }}> Generate A Link</button>
                                                    </div>
                                                )
                                                :
                                                (<div>
                                                    <div className="card-body  " >
                                                        <div className='row ' style={{ marginTop: '150px', marginBottom: '100px' }}>
                                                            <h4 className='' style={{ color: 'rgb(34, 51, 103)' }}><i className='fa fa-envelope'></i> Invite Memeber By Email</h4>
                                                            <form onSubmit={sentEmail} method='post'>
                                                                <input
                                                                    className='mt-4 mb-4 p-2 w-50'
                                                                    type="email" value={email}
                                                                    onChange={e => userEmail(e.target.value)}
                                                                    placeholder="Enter The Email Address"
                                                                    required />
                                                                <button type="submit" className='bg-c p-2'><i className='fa fa-envelope'></i>  {loading ? 'Sending Email...' : 'Send'}</button>
                                                            </form>

                                                        </div>
                                                        <div className='text-left card p-4 in' >
                                                            <h3 className='mb-4'>Invitation Link <i className='fa fa-link'></i></h3>
                                                            <DataTable
                                                                columns={column}
                                                                data={links}
                                                            >

                                                            </DataTable>
                                                        </div>


                                                    </div>

                                                </div>)}

                                        </div>
                                    )}

                                </div>
                            ) : (
                                <div>
                                    <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
                                </div>
                            )}
                        </div>




                    ) : (
                        window.location.replace('login')
                    )
                    }
                    {/* Small boxes (Stat box) */}
                    <ToastContainer />

                </div >
            </section >
        </div >


    );
};

export default Invite;