import axios from 'axios';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Http from '../Http';
import { redirect, useNavigate } from 'react-router';
import getUser from './Getuser';
import { Link } from 'react-router-dom';
import Geolocation from 'geolocation';
import LogoLoader from './loader';

const Login = () => {
    const [user] = useState(getUser());

    const [email, userEmail] = useState('');
    const [password, userErpass] = useState('');


    const validate = () => {
        let result = true;
        if (email === '' || email === null) {
            result = false
            // toast.warning('please enter the email');
            toast('please enter the email');
        }
        if (password === '' || password === null) {
            result = false
            toast('please enter the password');

        }
    }
    const Proccesslogin = (e) => {
        e.preventDefault();

        
        try {
            const response = axios.post(process.env.REACT_APP_API_URL+'/login', { email, password }).then((res) => {
                if (res.data.message == 'Login Successful') {
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('token', res.data.token);
                    window.location.replace('/home');
                    toast('Login Successful');
                } else {
                    toast(res.data.message);

                }
            });
            // Handle successful login, such as storing the token in local storage or context
        } catch (error) {
            console.log('not work');
            // Handle login error, such as displaying an error message
        }

    }
    //loction
    const [isLoading, setIsLoading] = useState(true);

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationError, setLocationError] = useState(null);
    useEffect(() => {

        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    useEffect(() => {

        let intervalId = null;

        const getLocation = () => {
            navigator.geolocation.getCurrentPosition(
                position => {
                    if (position && position.coords) {
                        const newLatitude = position.coords.latitude;
                        const newLongitude = position.coords.longitude;

                        setLatitude(newLatitude);
                        setLongitude(newLongitude);
                        setLocationError(null);

                        // Update local storage
                        localStorage.setItem('latitude', newLatitude);
                        localStorage.setItem('longitude', newLongitude);
                    }
                },
                error => {
                    setLocationError(error.message);
                }
            );
        };

        const startLocationUpdates = () => {
            getLocation(); // Get location immediately

            intervalId = setInterval(() => {
                getLocation(); // Get location every 50 seconds
            }, 50000);
        };

        startLocationUpdates();

        return () => {
            clearInterval(intervalId); // Clear the interval when the component unmounts
        };
    }, []);

    return (
    <div className="content-wrapper">
        {user ? (
                window.location.replace('/home')


            ) : (

                <section className="content " style={{ paddingTop: '50px' }}>
                    {
                        isLoading ? <LogoLoader /> : (
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-md-6 " style={{ margin: 'auto' }}>
                                        <div className="card text-center">
                                            <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                                <h4 className=" text-center" style={{ float: 'center' }}> PassMH Login  </h4>
                                            </div>
                                            <div className="card-body login-card-body p-4 ">
                                                <p className="login-box-msg"> <img src={window.location.origin + '/passpm.png'} width={'120'} /> </p>
                                                <form onSubmit={Proccesslogin} >
                                                    <div className="input-group mb-3 ">
                                                        <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={e => userEmail(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-envelope" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={e => userErpass(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">

                                                        {/* /.col */}
                                                        <div className="col-md-4">
                                                            <button type="submit" className="btn btn-block text-white" style={{ background: '#223367' }}>Sign In</button>
                                                        </div>
                                                        {/* /.col */}
                                                    </div>
                                                </form>

                                                <div className="mt-2 mb-4">
                                                    {/* /.social-auth-links */}
                                                    <p className="mb-1">
                                                        <a href="/reset_password" style={{ color: '#223367', textDecoration: 'underline !important' }} >Forgot  Password ?</a>
                                                    </p>
                                                    {/* <p className="mb-0">
                                                        <a href="/signup" className="text-center" style={{ color: '#223367' }}><i className='fa fa-user-plus' style={{ color: 'rgb(48, 80, 165)' }}></i> Register New Account</a>
                                                    </p> */}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                        )
                    }
                    {/* /.container-fluid */}
                </section>
            )}

            <ToastContainer />

        </div>

    );
}
export default Login;