// getCurrentPlan.js
import axios from 'axios';

export default async function getCurrentPlan() {
    const plan = localStorage.getItem('currentPlan');
    const expirationDate = localStorage.getItem('planExpiration');
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    if (plan && expirationDate && new Date() < new Date(expirationDate)) {
        console.log('old plan data has been returned from localstorage!')
        // If plan exists and hasn't expired, return it
        return JSON.parse(plan);
    } else {
        // If plan doesn't exist or has expired, fetch new data
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/details_plan`,{headers});
            if (response.status === 200) {
                const planData = response.data.data;
                console.log('new plan data has been fetched!')
                // Save the new plan data and expiration to localStorage
                localStorage.setItem('currentPlan', JSON.stringify(planData));
                localStorage.setItem('planExpiration', new Date(Date.now() + 24 * 60 * 60 * 1000)); // 1 day from now
                
                return planData;
            }
        } catch (error) {
            console.error("Error fetching the plan:", error);
            return null; // Return null if there's an error
        }
    }
}
