import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Http from '../Http';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import PlanProperties from './PlanProperties';
import {allPlansContent, packageFeatures } from './PlanProperties';
import PlanFeaturesList from './PlanFeaturesList';
const PricingAreaTwo = () => {
  const [package1, setStarted] = useState([]);
  const [package1Month, setpackageMonth] = useState([]);
  const [package1Price, setPackagePrice] = useState([]);
  const [package1user, setPackageuser] = useState([]);
  const [activeSection, setActiveSection] = useState('monthly');
  const [activeSection2, setActiveSection2] = useState('monthly');
  const [setp, setPack] = useState('');
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };
  const handleSectionChange2 = (section) => {
    setActiveSection2(section);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleEdit = (value) => {
    setPack(value)
    toggleModal();
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  useEffect(() => {
    // Simulating an asynchronous operation
    if (setp === 'Started') {
      setPackagePrice(6.95);
    } else if (setp === 'Professional') {
      setPackagePrice(9.95);

    } else {
      setPackagePrice(12.95);

    }
  }, [setp]);
  const handleSubmitStarted = async (e) => {
    e.preventDefault();
    try {


      let result = true;
      // if (package1 === '' || package1 === null) {
      //   result = false
      //   // toast.warning('please enter the email');
      //   toast('Please Choose  The  Package');
      // }
      if (package1Month === '' || package1Month === null) {
        result = false
        toast('Enter the month');

      }
      if (package1user === '' || package1user === null) {
        result = false
        toast('Enter the user');

      }


      const response = await axios.post(process.env.REACT_APP_API_URL+'/choose_package', {
        package1: setp && setp,
        package1Month: package1Month,
        package1user: package1user,
        package1Price: ((package1Price * package1user) * package1Month),


      }, { headers });
      if (response.data.message === "Go") {
        window.location.replace(response.data.url['redirect_url']);

      } else {
        toast(response.data.message);
      }

    } catch (error) {
      toast(error);
    }
  };
  return (
    <>
      {/* Pricing Area Two start */}
      <div className="pricing-area pd-top-120 pd-bottom-90" >
        <div className="container">
          <div className="section-title text-center" >
            <h6 className="sub-title" id='pricing' style={{ fontFamily: 'Noto Kufi Arabic' }}>
              {
                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pricing plan' : ' خطة التسعير'
              }

            </h6>
            <h2 className="title">
              <span style={{ fontFamily: 'Noto Kufi Arabic' }}>
                {
                  localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Best plan to get our Services' : 'أفضل خطة للحصول على خدماتنا'
                }

              </span>
            </h2>
          </div>

          <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
            <ModalHeader toggle={toggleModal}><span className='text-center' style={{ fontFamily: 'Noto Kufi Arabic' }}>
              {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Provide Subscription Details' : 'عرض تفاصيل الاشتراك'}

            </span></ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmitStarted}>
                <div className='row' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                  <div className='mt-4 mb-4 text-center text-dark'>
                    <h1 className='mb-2'>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Time tracking, simplified' :'تتبع الوقت، بسهولة وسلاسة'
                      }
                      
                    </h1>

                    <h4 className=' mt-2 mb-4'>
                      {
                            localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pay-as-you-grow pricing' :
                            'تسعير حسب الاستخدام'
                      }
                      
                    </h4>
                  </div>
                  {/* <div className='col-md-4 mt-2 mb-2'>
                    <select className='form-control' value={package1}
                      onChange={(e) => setStarted(e.target.value)} >
                      <option>Choose The Package</option>
                      <option value={'Started'} selected={setp && setp === 'Started' ? 'selected' : ''}>
                        Started
                      </option>
                      <option value={'Professional'} selected={setp && setp === 'Professional' ? 'selected' : ''}>
                        Professional
                      </option>  <option value={'Enterprise'} selected={setp && setp === 'Enterprise' ? 'selected' : ''}>
                        Enterprise
                      </option>
                    </select>
                  </div> */}
                  <div className='col-md-12 mt-2 mb-2 text-center'>
                    {
                      setp && setp ?
                        <div>
                          <p className='h5'> <span> 
                            { localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'You Have Chosen Plan' :
                            'لقد اخترت الخطة الاحترافية'}
                            
                            </span>   {setp === 'Started' ?
                             localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?  'Started' :'الخطة الأساسية' 
                             : setp === 'Professional' ? 
                             localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?        'Professional' :'الخطة الاحترافية'
                              : setp === 'Enterprise' ? 
                              localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?     'Enterprise' :'خطة المؤسسات' 
                              : ''}</p>
                        </div>

                        : ''
                    }
                  </div>
                  <div className='col-md-6 mt-2 mb-2'>
                    <input type="number" value={package1user}
                      onChange={(e) => setPackageuser(e.target.value)} className="form-control"
                       placeholder= {  localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Number  of User" :'عدد الموظفين'}/>
                  </div>
                  <div className='col-md-6 mt-2 mb-2'>
                    <input type="number" value={package1Month}
                      onChange={(e) => setpackageMonth(e.target.value)} className="form-control"
                       placeholder={ localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Number of Month' :'عدد الأشهر'} />
                  </div>
                  <div className='col-md-12 mt-4'>
                    <button type='submit'
                      className="btn border-radius-0 w-100" style={{ background: 'rgb(34, 51, 103)', color: 'white' }}
                    >
                      <i className='fa fa-paper-plane'></i> 
                   { localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pay Now' :' ادفع الآن'}    
                    </button>
                  </div>
                </div>
              </form>

            </ModalBody>
          </Modal>
          <div className='text-center' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }} >
            <button
              style={{
                backgroundColor: activeSection === 'monthly' ? 'rgb(34,51,103,1)' : 'gray',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleSectionChange('monthly')}
            >
              {
                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Monthly' : 'شهريًا'
              }

            </button>
            <button
              style={{
                backgroundColor: activeSection === 'yearly' ? 'rgb(34,51,103,1)' : 'gray',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleSectionChange('yearly')}
            >
              {
                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Yearly' : 'سنوياً'
              }

            </button>
          </div>
          {activeSection === 'monthly' && (
            <div className="row  mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner" style={{ paddingBottom: '170px' }}>
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }} >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Started' : 'الخطة الأساسية'
                      }

                    </h5>
                    <h2 className="mb-3">
                      6.95 SAR <sub>user/mo</sub>
                    </h2>
                    <a
                      className="btn btn-border-gray border-radius-0 w-100"
                     href='/signup'
                    >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }

                    </a>
                  </div>
                
                     <PlanFeaturesList packageName="Started" />

                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner price-active" style={{ paddingBottom: '130px' }}>
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Professional' : ' الخطة الاحترافية'
                      }

                    </h5>
                    <h2 className="mb-3">
                      9.95 SAR <sub>user/mo</sub>
                    </h2>
                    <a
                      className="btn btn-border-gray border-radius-0 w-100"
                        href='/signup'

                    // to="nailto:info@tamakan.com.sa"
                    >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }
                    </a>
                  </div>
                  <PlanFeaturesList packageName="Professional" />


                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner">
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Enterprise' : ' خطة المؤسسات'
                      }

                    </h5>
                    <h2 className="mb-3">
                      12.95 SAR <sub>user/mo</sub>
                    </h2>
                    <Link
                      className="btn btn-border-gray border-radius-0 w-100"
                        to='/signup'

                    >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }
                    </Link>
                  </div>
                  <PlanFeaturesList packageName="Enterprise" />


                </div>
              </div>
            </div>
          )}

          {activeSection === 'yearly' && (
            <div className="row ">
              <div className="col-lg-12 col-md-12 mt-4 mb-4 text-center">
                <h5> <strong className='text-danger ' >
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Save 20%' :
                      ' وفر 20٪'
                  }

                </strong></h5>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner" style={{ paddingBottom: '170px' }} >
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }} >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Started' : 'الخطة الأساسية'
                      }

                    </h5>
                    <h2 className="mb-3">
                      5.56 SAR <sub>user/mo</sub>
                    </h2>
                    <a
                      className="btn btn-border-gray border-radius-0 w-100"
                      href='/signup' style={{ fontFamily: 'Noto Kufi Arabic' }}
                    >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }
                    </a>
                  </div>
                  <ul style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }} >
                    <li >
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking' : ' تتبع الوقت'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Timesheets' : '   تقارير ساعات العمل'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Email Marketing & Automation' :
                          '  تسويق البريد الإلكتروني والأتمتة للمؤسسات'

                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Jobs, Breaks & Notes' : ' الوظائف، الاستراحات والملاحظات'
                      }

                    </li>
                    <li className="hide">
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Overtime' : ' العمل الإضافي'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Real-Time GPS Tracking' : ' تتبع GPS في الوقت الفعلي'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Report Generation' : ' إنشاء التقارير'

                      }


                    </li>
                  </ul>

                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner price-active" style={{ paddingBottom: '130px' }}>
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Professional' : ' الخطة الاحترافية'
                      }

                    </h5>
                    <h2 className="mb-3">
                      7.96 SAR <sub>user/mo</sub>
                    </h2>
                    <a
                      className="btn btn-border-gray border-radius-0 w-100"
                      href='/signup' style={{ fontFamily: 'Noto Kufi Arabic' }}

                    // to="nailto:info@tamakan.com.sa"
                    >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }
                    </a>
                  </div>
                  <ul style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking' : ' تتبع الوقت'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                          ' Timesheets & Report Generation' :
                          ' الجداول الزمنية وإنشاء التقارير'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Email Marketing & Automation' : ' تسويق البريد الإلكتروني والأتمتة للمؤسسات'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Jobs, Breaks & Notes' : ' الوظائف، الاستراحات والملاحظات'}

                    </li>
                    <li className="hide">
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Overtime' : ' العمل الإضافي'
                      }

                    </li>

                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Team Chat' : ' محادثة الفريق'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Real-Time GPS Tracking' : ' تتبع GPS في الوقت الفعلي'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Smart Notification' : ' إشعارات ذكية'
                      }

                    </li>
                  </ul>

                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-pricing-inner">
                  <div className="price-header text-center mb-lg-4">
                    <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Enterprise' : ' خطة المؤسسات'
                      }

                    </h5>
                    <h2 className="mb-3">
                      10.36 SAR <sub>user/mo</sub>
                    </h2>
                    <Link
                      className="btn btn-border-gray border-radius-0 w-100"
                       to='/signup'

                      style={{ fontFamily: 'Noto Kufi Arabic' }}     >
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Order Now' : 'اطلب الآن'
                      }
                    </Link>
                  </div>
                  <ul style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Time Tracking' : ' تتبع الوقت  '
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Timesheets & Report Generation' : ' الجداول الزمنية وإنشاء التقارير'
                      }


                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Email Marketing & Automation' : ' تسويق البريد الإلكتروني والأتمتة للمؤسسات'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Jobs, Breaks & Notes' : ' الوظائف، الاستراحات والملاحظات'
                      }

                    </li>
                    <li className="hide">
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Overtime' : ' العمل الإضافي'
                      }

                    </li>

                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Team Chat' : ' محادثة الفريق'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Real-Time GPS Tracking' : ' تتبع GPS في الوقت الفعلي'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Group Chating' : ' محادثات المجموعات'
                      }

                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' 24/7 Technical Support' : ' دعم فني 24/7'
                      }
                    </li>
                    <li>
                      <FaCheck />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Smart Notification' : ' إشعارات ذكية'
                      }

                    </li>
                  </ul>

                </div>
              </div>
            </div>
          )}

          <div className='price_compare'>
            <div className="section-title text-center">
              <h2 className="title text-capitalize" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                {
                  localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Compare plans & features' : ' قارن بين الخطط والميزات'
                }


              </h2>
            </div>
            <div className='row container mb-4' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
              <div className='col-md-12 text-center' >
                <button
                  style={{
                    backgroundColor: activeSection2 === 'monthly' ? 'rgb(34,51,103,1)' : 'gray',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSectionChange2('monthly')}
                >
                  {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Monthly' :
                    ' شهريًا'}
                </button>
                <button
                  style={{
                    backgroundColor: activeSection2 === 'yearly' ? 'rgb(34,51,103,1)' : 'gray',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSectionChange2('yearly')}
                >
                  {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Yearly' :
                    ' سنوياً'}

                </button>
              </div>
              {activeSection2 === 'monthly' && (
                <div className=' row mt-4 text-center' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                  <div className='col-md-3   border pt-4 pb-4'>
                    <h4 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Prices & Plans' : ' الأسعار والخطط'
                      }

                    </h4>
                    <h4 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Tracking features' : ' ميزات تتبع الوقت'
                      }

                    </h4>

                  </div>
                  <div className='col-md-3 border pt-4 pb-4' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <h4 style={{ fontFamily: 'Noto Kufi Arabic' }}><strong>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Started' : ' الخطة الأساسية'
                      }

                    </strong></h4>
                    <h3>6.95 SAR</h3>
                    <h6>User/ Month</h6>

                  </div>
                  <div className='col-md-3 border  pt-4 pb-4'>
                    <h4><strong style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Professional' : 'الخطة الاحترافية'
                      }

                    </strong></h4>
                    <h3>9.95 SAR</h3>
                    <h6>User/ Month</h6>
                  </div>
                  <div className='col-md-3 border  pt-4 pb-4'>
                    <h4><strong style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Enterprise' : 'خطة المؤسسات'}

                    </strong></h4>
                    <h3>12.95 SAR</h3>
                    <h6>User/ Month</h6>

                  </div>
                </div>
              )}

              {activeSection2 === 'yearly' && (
                <div className=' row mt-4 text-center'>
                  <div className='col-md-3   border pt-4 pb-4'>
                    <h4 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Prices & Plans' : 'الأسعار والخطط'
                      }

                    </h4>
                    <h4 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Tracking features' : " ميزات التتبع"
                      }

                    </h4>

                  </div>
                  <div className='col-md-3   border pt-4 pb-4'>
                    <h4><strong style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Started' : 'الخطة الأساسية'
                      }

                    </strong></h4>
                    <h3>5.56 SAR</h3>
                    <h6>User/ Month</h6>

                  </div>
                  <div className='col-md-3 border  pt-4 pb-4'>
                    <h4><strong style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Professional' : ' الخطة الاحترافية'
                      }

                    </strong></h4>
                    <h3>7.96 SAR</h3>
                    <h6>User/ Month</h6>
                  </div>
                  <div className='col-md-3 border  pt-4 pb-4' >
                    <h4><strong style={{ fontFamily: 'Noto Kufi Arabic' }}>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Enterprise' : 'خطة المؤسسات'
                      }

                    </strong></h4>
                    <h3>10.36 SAR</h3>
                    <h6>User/ Month</h6>

                  </div>

                </div>
              )}
              {/* <div className=' row '>

                <div className='col-md-3  border ' >
                  <p className='pt-2  ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Mobile & Web Time Tracking' : 'تتبع الوقت'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Timesheets & Report Generation' : ' الجداول الزمنية وإنشاء التقارير'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Email Marketing & Automation' : ' تسويق البريد الإلكتروني والأتمتة للمؤسسات'}

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Jobs, Breaks & Notes' :
                        ' الوظائف، الاستراحات والملاحظات'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Overtime' : 'العمل الإضافي'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Real-Time GPS Tracking ' : ' تتبع GPS في الوقت الفعلي'}

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Team Chat ' : 'محادثة الفريق'
                    }

                  </p>

                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Group Chating' : 'محادثات المجموعات'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '24/7 Technical Support' : ' دعم فني 24/7'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Smart Notification' :
                        'إشعارات ذكية'
                    }

                  </p>
                  <p className='pt-2 ' style={{ color: '#000' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Others(API Access)' : ' أخرى (الوصول إلى API)'
                    }

                  </p>

                </div>
                <div className='col-md-3   border text-center'>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" #fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </div>
                <div className='col-md-3   border text-center'>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" #fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>

                  <p className='pt-2'>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill="#fff" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </div>
                <div className='col-md-3   border text-center'>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                  </p>
                  <p className='pt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>

                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                  <p className='pt-2 '>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>5DF638FE-D8D1-469E-91A2-C99212E188E3</title>
                      <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Atto-/-Pricing-Updated" transform="translate(-523, -1932)">
                          <g id="Group-24-Copy" transform="translate(150, 1659)">
                            <g id="circle_tick_black" transform="translate(373, 273)">
                              <circle id="Oval-6" fill=" rgb(34, 51, 103)" cx={12} cy={12} r={12} />
                              <g id="black_tick" transform="translate(12.0175, 10.7175) rotate(-45) translate(-12.0175, -10.7175)translate(6.0175, 7.2175)" fill="#FFFFFF">
                                <path d="M0,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 C12,6.32842712 11.3284271,7 10.5,7 L1,7 C0.44771525,7 3.38176876e-17,6.55228475 0,6 L0,4 L0,4 Z" id="Rectangle" />
                                <path d="M-2,2 L3.5,2 C4.32842712,2 5,2.67157288 5,3.5 C5,4.32842712 4.32842712,5 3.5,5 L-1,5 C-1.55228475,5 -2,4.55228475 -2,4 L-2,2 L-2,2 Z" id="Rectangle" transform="translate(1.5, 3.5) scale(1, -1) rotate(90) translate(-1.5, -3.5)" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </div>
              </div> */}
              <PlanProperties/>
            </div>
          </div>

        </div>


      </div >

      {/* Pricing Area Two start */}
    </>
  );
};

export default PricingAreaTwo;
