import React, { useState, useEffect } from 'react';
import getUser from './Getuser';
import axios from 'axios';
import { toast } from 'react-toastify';
import LogoLoader from './loader';


const Profile = () => {
    const [user] = useState(getUser());
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);

    useEffect(() => {
        // Retrieve user profile values from local storage
        const storedProfile = localStorage.getItem('user');
        if (storedProfile) {
            const user = JSON.parse(storedProfile);
            setName(user.name || '');
            setEmail(user.email || '');
            setPhone(user.phone || '');

        }
    }, []);
    const handleNameChange = async (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        try {
            const response = await axios.post(process.env.REACT_APP_API_UR+'/update_profile', {
                name: name,
                phone: phone,
                email: email,
                password: password
            }, { headers });
            const existingData = JSON.parse(localStorage.getItem('user'));
            existingData.name = name;
            existingData.email = email;
            existingData.phone = phone;
            localStorage.setItem('user', JSON.stringify(existingData));

            toast(response.data.message);
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    };
    return (
        <div>
            {user ? (
                <div>
                    {isLoading ? <LogoLoader /> :
                        <form onSubmit={handleSubmit}>

                            <div className="container rounded bg-white mt-5 mb-5 " style={{ textAlign: 'left' }}>
                                <div className="row " style={{ marginTop: '100px' }}>
                                    <div className="col-md-6 border   " style={{ margin: 'auto', border: '1px solid #007bff', marginBottom: '200px' }}>
                                        <div className="d-flex flex-column align-items-center text-center ">
                                            <img className="rounded-circle " width="150px" src={window.location.origin + '/passpm.png'} />
                                        </div>
                                        <div className="p-3 py-5">
                                            <div className="d-flex justify-content-between  align-items-center mb-3">
                                                <h4 className="text-right ">Your Account Details</h4>
                                            </div>
                                            <div className="row mt-2">

                                                <div className="col-md-6">
                                                    <label className="labels">Name</label>
                                                    <input type="text" name='name' className="form-control" value={name} onChange={handleNameChange} />
                                                </div>
                                                <div className="col-md-6 "><label className="labels">Phone</label>
                                                    <input type="number" name='phone' className="form-control" value={phone} onChange={handlePhoneChange} />
                                                </div>
                                                <div className="col-md-12 mt-2"><label className="labels">E-Mail</label>
                                                    <input type="email" name='email' className="form-control" value={email} onChange={handleEmailChange} disabled />
                                                </div>
                                                <div className="col-md-12 mt-2"><label className="labels">Password</label>
                                                    <input type="password" name='password' className="form-control" value={password} onChange={handlePasswordChange} />
                                                </div>
                                            </div>

                                            <div className="mt-5 text-center">
                                                <button className=" bg-c p-2" type="submit"> <i className='fa fa-save'></i> Update Profile</button>
                                            </div>


                                        </div>
                                    </div>


                                </div>
                            </div>

                        </form>
                    }

                </div>




            ) : (
                window.location.replace('login')
            )
            }

        </div >


    );
}
export default Profile;
